import {
  QueryContainerGroupLogsProblemType,
  ResponseError,
  SystemLogsProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, switchMap, takeUntil, timer } from 'rxjs'
import { ContainerGroupsAPI, SystemLogsAPI } from '../apiMethods'
import {
  getContainerGroupAndInstances,
  setContainerGroup,
} from '../features/containerGroupDetail/containerGroupDetailSlice'
import { containerGroupInstancesAdded } from '../features/containerGroupInstances/containerGroupInstancesSlice'
import { containerGroupLogsAdded, getContainerGroupLogs } from '../features/containerGroupLogs/containerGroupLogsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getSystemEventLogs,
  startPollingSystemEventLogs,
  stopPollingSystemEventLogs,
  systemEventLogsAdded,
} from '../features/systemEventLogs/systemEventLogsSlice'
import { getUnableToGetContainerGroupAndInstancesErrorContent } from '../notifications/clientToastNotificationContent/containerGroupDetails'
import {
  GetContainerGroupLogsAxiomTimeouErrorContent,
  getContainerGroupLogsConnectionTimeoutErrorContent,
  getContainerGroupLogsGeneralErrorContent,
  getContainerGroupLogsNotFoundErrorContent,
  getContainerGroupLogsOrganizationNotFoundErrorContent,
} from '../notifications/clientToastNotificationContent/containerGroupLogs'
import {
  getSystemEventLogsConnectionTimeoutErrorContent,
  getSystemEventLogsContainerGroupNotFoundErrorContent,
  getSystemEventLogsGeneralErrorContent,
  getSystemEventLogsOrganizationNotFoundErrorContent,
} from '../notifications/clientToastNotificationContent/systemEventLogs'
import { getContainerGroupsPagePath, getOrganizationsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetContainerGroupAndInstances: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupAndInstances.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'pending' })),
        from(
          Promise.all([
            ContainerGroupsAPI.getContainerGroup({
              containerGroupName,
              organizationName,
              projectName,
            }),
            ContainerGroupsAPI.listContainerGroupInstances({
              containerGroupName,
              organizationName,
              projectName,
            }),
          ]),
        ).pipe(
          mergeMap(([containerGroupResponse, ContainerGroupInstancesResponse]) =>
            concat(
              of(
                setContainerGroup({ containerGroup: containerGroupResponse }),
                containerGroupInstancesAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  instances: ContainerGroupInstancesResponse.instances,
                }),
                setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                showToastNotification(getUnableToGetContainerGroupAndInstancesErrorContent(intl)),
                setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetContainerGroupLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupLogs.match),
    switchMap(({ payload: { containerGroupLogsQuery, containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'pending' })),
        from(
          ContainerGroupsAPI.queryContainerGroupLogs({
            containerGroupName,
            organizationName,
            projectName,
            containerGroupLogsQuery,
          }),
        ).pipe(
          mergeMap((containerGroupLogsResponse) =>
            concat(
              of(
                containerGroupLogsAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  containerGroupLogs: containerGroupLogsResponse.items,
                }),
                setRequestStatus({ request: 'getContainerGroupLogs', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            concat(
              error instanceof ResponseError
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as QueryContainerGroupLogsProblemType | null
                      switch (errorType) {
                        case QueryContainerGroupLogsProblemType.ConnectionTimeout:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsConnectionTimeoutErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case QueryContainerGroupLogsProblemType.LogApiFailed:
                          return concat(
                            of(
                              showToastNotification(GetContainerGroupLogsAxiomTimeouErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case QueryContainerGroupLogsProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case QueryContainerGroupLogsProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        default:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsGeneralErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                      }
                    }),
                  )
                : concat(
                    of(
                      showToastNotification(getContainerGroupLogsGeneralErrorContent(intl)),
                      setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                    ),
                    of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                  ),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetSystemEventLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getSystemEventLogs.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName, systemLogsQuery } }) =>
      concat(
        of(setRequestStatus({ request: 'getSystemEventLogs', status: 'pending' })),
        from(
          SystemLogsAPI.queryContainerGroupSystemLogs({
            containerGroupName,
            organizationName,
            projectName,
            systemLogsQuery,
          }),
        ).pipe(
          mergeMap((systemEventLogsResponse) =>
            concat(
              of(
                systemEventLogsAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  systemEventLogs: systemEventLogsResponse.items,
                }),
                setRequestStatus({ request: 'getSystemEventLogs', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            concat(
              error instanceof ResponseError
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as SystemLogsProblemType | null
                      switch (errorType) {
                        case SystemLogsProblemType.ConnectionTimeout:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsConnectionTimeoutErrorContent(intl)),
                              setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case SystemLogsProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case SystemLogsProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsContainerGroupNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsGeneralErrorContent(intl)),
                              setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                          )
                      }
                    }),
                  )
                : concat(
                    of(
                      showToastNotification(getSystemEventLogsGeneralErrorContent(intl)),
                      setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                    ),
                    of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                  ),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollSystemEventLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(startPollingSystemEventLogs.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName, systemEventLogFilters } }) =>
      concat(
        of(setRequestStatus({ request: 'pollSystemEventLogs', status: 'pending' })),
        timer(0, 10000).pipe(
          mergeMap(() =>
            from(
              SystemLogsAPI.queryContainerGroupSystemLogs({
                containerGroupName,
                organizationName,
                projectName,
                systemLogsQuery: systemEventLogFilters,
              }),
            ).pipe(
              mergeMap((systemEventLogsResponse) =>
                concat(
                  of(
                    systemEventLogsAdded({
                      containerGroupName,
                      organizationName,
                      projectName,
                      systemEventLogs: systemEventLogsResponse.items,
                    }),
                    setRequestStatus({ request: 'pollSystemEventLogs', status: 'succeeded' }),
                  ),
                  of(setRequestStatus({ request: 'pollSystemEventLogs', status: 'idle' })).pipe(delay(1000)),
                ),
              ),
              catchError((error: unknown) =>
                concat(
                  error instanceof ResponseError
                    ? from(error.response.json()).pipe(
                        mergeMap((errorResponse) => {
                          const errorType = errorResponse.type as SystemLogsProblemType | null
                          switch (errorType) {
                            case SystemLogsProblemType.ConnectionTimeout:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsConnectionTimeoutErrorContent(intl)),
                                  setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                                ),
                                of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                                of(stopPollingSystemEventLogs()),
                              )
                            case SystemLogsProblemType.OrganizationNotFound:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsOrganizationNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                                ),
                                of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                                of(stopPollingSystemEventLogs()),
                              )
                            case SystemLogsProblemType.ContainerGroupNotFound:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsContainerGroupNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                                ),
                                of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                                of(stopPollingSystemEventLogs()),
                              )
                            default:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsGeneralErrorContent(intl)),
                                  setRequestStatus({ request: 'getSystemEventLogs', status: 'failed' }),
                                ),
                                of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                                of(stopPollingSystemEventLogs()),
                              )
                          }
                        }),
                      )
                    : concat(
                        of(
                          showToastNotification(getSystemEventLogsGeneralErrorContent(intl)),
                          setRequestStatus({ request: 'pollSystemEventLogs', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'getSystemEventLogs', status: 'idle' })).pipe(delay(1)),
                        of(stopPollingSystemEventLogs()),
                      ),
                ),
              ),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingSystemEventLogs.match))),
        ),
      ),
    ),
  )
