import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { VerticalProgressBar } from '../../block/VerticalProgressBar'
import type { VerticalProgressBarStep } from '../../block/VerticalProgressBarWithClick'
import { CreateOrganizationWizardPageMessages } from '../messages/CreateOrganizationWizardPageMessages'
import { CreateOrganizationWizardPageSteps } from '../models/'

interface CreateOrganizationWizardProgressBarProps {
  /** The current page for the create organization wizard. */
  currentPage: CreateOrganizationWizardPageSteps
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
}

export const CreateOrganizationWizardProgressBar: FunctionComponent<CreateOrganizationWizardProgressBarProps> = ({
  currentPage,
  isFreeTrial,
}) => {
  const intl = useIntl()

  const handleUpdatedSteps = (currentPage: CreateOrganizationWizardPageSteps): VerticalProgressBarStep[] => {
    const steps = [
      {
        label: intl.formatMessage(CreateOrganizationWizardPageMessages.organizationInformationLabel),
        status: currentPage === CreateOrganizationWizardPageSteps.OrganizationInformation ? 'current' : 'completed',
      },
      {
        label: intl.formatMessage(CreateOrganizationWizardPageMessages.configureCreditSettingsLabel),
        status: currentPage === CreateOrganizationWizardPageSteps.ConfigureCreditSettings ? 'current' : 'completed',
      },
      {
        label: intl.formatMessage(CreateOrganizationWizardPageMessages.addPaymentMethodLabel),
        status: currentPage === CreateOrganizationWizardPageSteps.AddPaymentMethod ? 'current' : 'completed',
      },
      {
        label: intl.formatMessage(CreateOrganizationWizardPageMessages.paymentSummaryLabel),
        status: currentPage === CreateOrganizationWizardPageSteps.PaymentSummary ? 'current' : 'completed',
      },
      {
        label: intl.formatMessage(CreateOrganizationWizardPageMessages.inviteTeamMembersLabel),
        status: currentPage === CreateOrganizationWizardPageSteps.InviteTeamMembers ? 'current' : 'completed',
      },
    ]

    if (isFreeTrial) {
      steps.splice(3, 1)
    }

    return steps as VerticalProgressBarStep[]
  }

  return (
    <div className="h-48 p-4">
      <VerticalProgressBar steps={handleUpdatedSteps(currentPage)} />
    </div>
  )
}
