import { defineMessages } from 'react-intl'

export const GetInstanceLogsDownloadSuccessfullyStartedContentMessages = defineMessages({
  body: {
    defaultMessage: `You have successfully started downloading your instance logs.`,
    id: '8r2C31QV',
    description: `The toast notification body shown when we start downloading the user's instance logs.`,
  },
  title: {
    defaultMessage: 'Download started',
    id: 'rC9u0tyQ',
    description: "The toast notification title shown when we start downloading the user's instance logs.",
  },
})
