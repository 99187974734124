import { defineMessages } from 'react-intl'

export const GetSystemEventLogsDownloadErrorMessages = defineMessages({
  body: {
    defaultMessage: `Please try again. If the issue persists, {contact_support}.`,
    id: 'cVU2ks+J',
    description:
      'The description shown in a toast notification message when an error occurs while downloading system event logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Tj48Q4p4',
    description: 'The link text shown in a toast notification message.',
  },
  title: {
    defaultMessage: 'Unable to retrieve System Event Logs',
    id: 'e7eNx700',
    description: 'The toast notification title shown when we encounter an error downloading the system event logs.',
  },
})
