import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { GetInstanceLogsDownloadSuccessfullyStartedContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user successfully starts
 * downloading their instance logs
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getInstanceLogsDownloadSuccessfullyStartedContent = (
  intl: IntlShape,
): {
  body: ReactNode
  title: string
  type: ToastNotificationType
} => {
  return {
    body: intl.formatMessage(GetInstanceLogsDownloadSuccessfullyStartedContentMessages.body),
    type: 'success',
    title: intl.formatMessage(GetInstanceLogsDownloadSuccessfullyStartedContentMessages.title),
  }
}
