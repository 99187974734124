import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizard } from '../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  editCreateOrganizationWizard,
  getCreateOrganizationWizard,
} from '../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { CreateOrganizationWizardPath } from '../../routes/routePaths'
import { getCreateOrganizationWizardOrganizationInformationPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { CreateOrganizationWizardConfigureCreditSettingsPage } from './CreateOrganizationWizardConfigureCreditSettingsPage'
import { CreditSettingsFormValues } from './models'

export const ConnectedCreateOrganizationWizardConfigureCreditSettingsPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isEditCreateOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'editCreateOrganizationWizard') === 'pending',
  )
  const isGetOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getCreateOrganizationWizard') === 'pending',
  )
  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )

  const handleConfigureCreditSettings = useCallback(
    (values: CreditSettingsFormValues) => {
      const { automaticRecharge, automaticRechargeAmount, automaticRechargeThreshold, initialCredit } = values
      dispatch(
        editCreateOrganizationWizard({
          // The backend accepts the values in cents, so we need to multiply the values by 100
          createOrganizationWizard: {
            id: createOrganizationWizardId,
            automaticRecharge,
            ...(automaticRechargeAmount && { automaticRechargeAmount: automaticRechargeAmount * 100 }),
            ...(automaticRechargeThreshold && { automaticRechargeThreshold: automaticRechargeThreshold * 100 }),
            initialCredit: initialCredit === 'other' ? 0 : Number(initialCredit) * 100,
          },
          currentRoute: CreateOrganizationWizardPageSteps.ConfigureCreditSettings,
        }),
      )
    },
    [dispatch, createOrganizationWizardId],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const thresholdAmount = createOrganizationWizard?.automaticRechargeThreshold
  const rechargeAmount = createOrganizationWizard?.automaticRechargeAmount

  const creditSettingsDefaultValues = {
    initialCredit: createOrganizationWizard?.initialCredit,
    automaticRechargeThreshold: thresholdAmount && thresholdAmount !== 0 ? thresholdAmount : undefined,
    automaticRechargeAmount: rechargeAmount && rechargeAmount !== 0 ? rechargeAmount : undefined,
    automaticRecharge: createOrganizationWizard?.automaticRecharge,
  }

  const onBack = useCallback(() => {
    navigate(getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizardId))
  }, [navigate, createOrganizationWizardId])

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.ConfigureCreditSettings },
      }),
    )
  }, [dispatch, createOrganizationWizardId])

  return isGetOrganizationWizardPending ? (
    <LoadingPage />
  ) : (
    <CreateOrganizationWizardConfigureCreditSettingsPage
      creditSettingsDefaultValues={creditSettingsDefaultValues}
      isEditCreateOrganizationWizardPending={isEditCreateOrganizationWizardPending}
      onBack={onBack}
      onConfigureCreditSettings={handleConfigureCreditSettings}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
    />
  )
}
