import { defineMessages } from 'react-intl'

export const ContainerGroupsDeploymentTagsMessages = defineMessages({
  authenticationRequired: {
    defaultMessage: 'Authentication Required',
    id: '5PT2XftP',
    description: 'The label for the networking pill when authentication is required.',
  },
  open: {
    defaultMessage: 'Open',
    id: 'ZsA1hKEf',
    description: 'The label for the networking pill when authentication is not required.',
  },
})
