import type { ContainerGroup, RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useWatch,
  type Control,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormGetFieldState,
  type UseFormResetField,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { InlineNotification } from '../../../../components/block/InlineNotification'
import { EstimatedCost } from '../../../../components/containerGroups/EstimatedCost'
import type { AllocatedResources } from '../../../../components/containerGroups/models'
import type { ClientGpuOptions } from '../../../../features/gpuClasses/models'
import { scrollToElement } from '../../../../utils'
import { EditContainerGroupMainContentMessages } from '../../messages'
import {
  EditContainerGroupField,
  EditContainerGroupFormSectionIdAttributes,
  type EditContainerGroupValues,
} from '../../models'
import { getSectionIdWithFirstValidationError } from '../../utils'
import { EditCommandFields } from '../EditCommandFields'
import { EditContainerGatewayFields } from '../EditContainerGatewayFields'
import { EditDiskSpaceInput } from '../EditDiskSpaceInput'
import { EditDisplayNameInput } from '../EditDisplayNameInput'
import { EditEnvironmentVariablesFields } from '../EditEnvironmentVariablesFields'
import { EditGPUInput } from '../EditGPUInput'
import { EditLivenessProbeFields, EditReadinessProbeFields, EditStartupProbeFields } from '../EditHealthProbeFields'
import { EditImageSourceFields } from '../EditImageSourceFields'
import { EditLoggingFields } from '../EditLoggingFields'
import { EditMemoryInput } from '../EditMemoryInput'
import { EditPriorityInput } from '../EditPriorityInput'
import { EditReplicaCountInput } from '../EditReplicaCountInput'
import { EditVCPUInput } from '../EditVCPUInput'

interface EditContainerGroupMainContentProps {
  /** The selected resource options (GPU, vCPU, and Memory) along with their cost per hour */
  allocatedResources: AllocatedResources
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The Container Group */
  currentContainerGroup: ContainerGroup
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The gpu options the user has to select from */
  gpuOptions: ClientGpuOptions
  /** The flag indicating if the form has had changes */
  isEditFormDirty: boolean
  /** The flag indicating that the request to create a container group is pending. */
  isEditContainerGroupDetailsPending: boolean
  /** The link to the typeform for requesting an increase in the container group instance quota limit. */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
  /** The react hook form method that gets the current state for any field. */
  onGetFieldState: UseFormGetFieldState<FieldValues>
  /** The submit handler for the create container group react hook form. */
  onHandleSubmit: (event: React.SyntheticEvent<Element, Event>) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The replica count */
  replicaCount: number
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditContainerGroupMainContent: FunctionComponent<EditContainerGroupMainContentProps> = ({
  allocatedResources,
  clearErrors,
  control,
  currentContainerGroup,
  gpuOptions,
  isEditContainerGroupDetailsPending,
  isEditFormDirty,
  linkToRequestIncreaseForContainerGroupInstancesQuotas,
  maxReplicaCount,
  onGetFieldState,
  onHandleSubmit,
  onRecordMixpanelElementClickedEvent,
  ramOptions,
  replicaCount,
  resetField,
  setValue,
  storageOptions,
  trigger,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isContainerGatewaySidePanelOpen, setIsContainerGatewaySidePanelOpen] = useState<boolean>(false)
  const { container, name, networking } = currentContainerGroup || {}

  const containerGroupDisplayNameRef = useRef<HTMLDivElement>(null)
  const imageSourceRef = useRef<HTMLDivElement>(null)
  const containerGatewayAndEnvironmentVariablesRef = useRef<HTMLDivElement>(null)
  const resourceAllocationRef = useRef<HTMLDivElement>(null)
  let timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const gpu: string[] | undefined = useWatch({ control, name: EditContainerGroupField.GPU })
  const isAutoScalingEnabled = useWatch({ control, name: EditContainerGroupField.AUTOSCALING_ENABLED })
  const isGPUSelected = gpu !== undefined && gpu.length > 0

  // The timeout is needed to ensure the validation errors are set in `react-hook-form` before scrolling
  // to the first error
  const validationTimeout = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      const sectionIdWithFirstValidationError = getSectionIdWithFirstValidationError(onGetFieldState)
      if (sectionIdWithFirstValidationError) {
        scrollToElement(sectionIdWithFirstValidationError)
      }
    }, 50)
  }, [onGetFieldState])

  const handleFormSubmit = (event: React.SyntheticEvent<Element, Event>) => {
    validationTimeout()
    onHandleSubmit(event)
  }

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  return (
    <div className="relative h-screen w-full pb-40">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-12 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(EditContainerGroupMainContentMessages.backToContainerGroupDetails, {
                container_group_name: name,
              })}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(EditContainerGroupMainContentMessages.title, {
              container_group_name: name,
            })}
          </h1>
          <p className="mb-4 text-base">{intl.formatMessage(EditContainerGroupMainContentMessages.subtitle)}</p>

          <p className="mb-2 text-base font-bold">
            {intl.formatMessage(EditContainerGroupMainContentMessages.containerNameTitle)}
          </p>
          <p className="mb-2 text-xl font-bold text-green-80">{name}</p>
          <p className="mb-10 text-xs">
            {intl.formatMessage(EditContainerGroupMainContentMessages.containerNameDescription)}
          </p>

          <EditDisplayNameInput
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_DISPLAY_NAME}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={containerGroupDisplayNameRef}
          />

          <EditImageSourceFields
            control={control}
            currentImageSource={container.image}
            currentImageSourceHash={container.hash}
            id={EditContainerGroupFormSectionIdAttributes.IMAGE_SOURCE}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={imageSourceRef}
            resetField={resetField}
            trigger={trigger}
          />

          <InlineNotification
            type="info"
            title={intl.formatMessage(EditContainerGroupMainContentMessages.imageSourceBannerTitle)}
            body={intl.formatMessage(EditContainerGroupMainContentMessages.imageSourceBannerBody, {
              image_url: (
                <span className="inline-block max-w-xl truncate align-bottom font-bold">{container.image}</span>
              ),
            })}
          />

          <div ref={resourceAllocationRef}>
            <h2 className="mb-10 text-xl font-bold">
              {intl.formatMessage(EditContainerGroupMainContentMessages.resourceAllocationSectionHeader)}
            </h2>

            <EditReplicaCountInput
              control={control}
              id={EditContainerGroupFormSectionIdAttributes.REPLICA_COUNT}
              max={maxReplicaCount}
              requestIncreaseLimitLink={linkToRequestIncreaseForContainerGroupInstancesQuotas}
            />
            <EditVCPUInput control={control} id={EditContainerGroupFormSectionIdAttributes.VCPU} />
            <EditMemoryInput
              control={control}
              id={EditContainerGroupFormSectionIdAttributes.MEMORY}
              ramOptions={ramOptions}
            />
            <EditGPUInput
              control={control}
              gpuOptions={gpuOptions}
              id={EditContainerGroupFormSectionIdAttributes.GPU}
            />
            <EditPriorityInput
              control={control}
              currentPriority={container.priority ?? undefined}
              id={EditContainerGroupFormSectionIdAttributes.PRIORITY}
              isGPUSelected={isGPUSelected}
              resetField={resetField}
              setValue={setValue}
            />
            <EditDiskSpaceInput
              control={control}
              id={EditContainerGroupFormSectionIdAttributes.DISK_SPACE}
              storageOptions={storageOptions}
            />
          </div>

          <h2 className="mb-10 text-xl font-bold" ref={containerGatewayAndEnvironmentVariablesRef}>
            {intl.formatMessage(EditContainerGroupMainContentMessages.optionalSettingsSectionHeader)}
          </h2>

          <h2 className="mb-10 text-lg font-bold">
            {intl.formatMessage(EditContainerGroupMainContentMessages.healthCheckProbesSectionHeader)}
          </h2>
          <EditStartupProbeFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.STARTUP_PROBE}
            setValue={setValue}
            trigger={trigger}
          />
          <EditLivenessProbeFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.LIVENESS_PROBE}
            setValue={setValue}
            trigger={trigger}
          />
          <EditReadinessProbeFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.READINESS_PROBE}
            setValue={setValue}
            trigger={trigger}
          />

          <EditCommandFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.COMMAND}
            trigger={trigger}
          />

          <EditContainerGatewayFields
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY}
            isContainerGatewaySidePanelOpen={isContainerGatewaySidePanelOpen}
            setIsContainerGatewaySidePanelOpen={setIsContainerGatewaySidePanelOpen}
            networking={networking}
            trigger={trigger}
          />
          <EditLoggingFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            resetField={resetField}
            trigger={trigger}
          />
          <EditEnvironmentVariablesFields
            clearErrors={clearErrors}
            control={control}
            id={EditContainerGroupFormSectionIdAttributes.ENVIRONMENT_VARIABLES}
            trigger={trigger}
          />

          <div className="mt-10 md:hidden">
            <EstimatedCost
              allocatedResources={allocatedResources}
              replicaCount={replicaCount}
              isAutoScalingEnabled={isAutoScalingEnabled}
            />
          </div>
        </div>
      </div>

      <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-center bg-neutral-10 px-14 py-7 align-middle shadow">
        <Button
          variant="green-filled"
          type="button"
          isFullWidth
          isLoading={isEditContainerGroupDetailsPending}
          isDisabled={isEditContainerGroupDetailsPending || !isEditFormDirty}
          onClick={handleFormSubmit}
        >
          {intl.formatMessage(EditContainerGroupMainContentMessages.saveButtonLabel)}
        </Button>
      </div>
    </div>
  )
}
