import type {
  AutomaticRechargeSettings,
  GetOrganizationBillingProfilePaymentMethod,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import React, { Suspense, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import type { Tab } from '../../../../components/Tabs'
import { Tabs } from '../../../../components/Tabs'
import { Link } from '../../../../components/base'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { BillingMainContentMessages } from '../../messages'
import { BillingCredits } from '../BillingCredits'
import { BillingInvoices } from '../BillingInvoices'
import { BillingUsage } from '../BillingUsage'

interface BillingMainContentProps {
  /** The automatic recharge settings. */
  automaticRechargeSettings?: AutomaticRechargeSettings
  /** The current credit amount in cents. */
  creditAmount: number
  /** The billing credits dashboard embed url. */
  billingCreditsEmbedUrl: string | undefined
  /** The invoice dashboard embed url. */
  billingInvoiceEmbedUrl: string | undefined
  /** The billing usage dashboard embed url. */
  billingUsageEmbedUrl: string | undefined
  /** The path for editing automatic recharge settings. */
  editAutomaticRechargeSettingsPath: string
  /** The flag indicating if the request to create the billing profile setup intents is pending. */
  isCreateBillingProfileSetupIntentsPending: boolean
  /** The flag indicating if the request to disable auto recharge settings is pending. */
  isDisableAutoRechargeOptOutPending: boolean
  /** The flag indicating if the request to disable auto recharge settings is successful. */
  isDisableAutoRechargeOptOutSuccessful: boolean
  /** The flag indicating if the request to edit auto recharge settings is pending. */
  isEditAutoRechargeSettingsPending: boolean
  /** The flag indicating if the request to load billing customer portal is pending. */
  isLoadBillingCustomerPortalPending: boolean
  /** The flag indicating if the request to verify payment method is pending. */
  isPaymentMethodVerificationInProgress: boolean
  /** The flag indicating if the request to remove payment method is pending. */
  isRemovePaymentMethodPending: boolean
  /** The flag indicating if the request to remove payment method was successful. */
  isRemovePaymentMethodSuccessful: boolean
  /** The path for manually adding credit. */
  manualCreditRequestAddCreditPath: string
  /** The callback executed when the user clicks cancel for the add payment method form. */
  onCancelAddPaymentMethod: () => void
  /** The callback executed when the user confirms the auto recharge opt out. */
  onConfirmAutoRechargeOptOut: () => void
  /** A callback that when executed creates a setup intent in order to add/update their payment method. */
  onCreateBillingProfileSetupIntents: () => void
  /**
   * A callback that when executed fetches a link that directs the user to their Metronome account where they could view
   * their statements.
   */
  onManageBilling: () => void
  /** The callback that navigates users to the auto recharge page. */
  onNavigateToBillingAutoRechargePage: () => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback executed when the user clicks the remove payment method button. */
  onRemovePaymentMethod: () => void
  /** The callback handler for when the Stripe submit form hits an error. */
  onSubmitFormError: (message?: string, status?: string) => void
  /** The callback handler for when the Stripe submit form succeeded. */
  onSubmitFormSucceeded: () => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The billing payment method. */
  paymentMethod?: GetOrganizationBillingProfilePaymentMethod
  /** The billing payment method. */
  paymentMethodBillingClientSecret: string | undefined
}

const BillingInformationNew = React.lazy(() =>
  import('../BillingInformationNew').then((module) => ({ default: module.BillingInformationNew })),
)

export const BillingMainContent: FunctionComponent<BillingMainContentProps> = ({
  automaticRechargeSettings,
  billingCreditsEmbedUrl,
  billingInvoiceEmbedUrl,
  billingUsageEmbedUrl,
  creditAmount,
  editAutomaticRechargeSettingsPath,
  isCreateBillingProfileSetupIntentsPending,
  isDisableAutoRechargeOptOutPending,
  isDisableAutoRechargeOptOutSuccessful,
  isEditAutoRechargeSettingsPending,
  isLoadBillingCustomerPortalPending,
  isPaymentMethodVerificationInProgress,
  isRemovePaymentMethodPending,
  isRemovePaymentMethodSuccessful,
  manualCreditRequestAddCreditPath,
  onCancelAddPaymentMethod,
  onConfirmAutoRechargeOptOut,
  onCreateBillingProfileSetupIntents,
  onManageBilling,
  onNavigateToBillingAutoRechargePage,
  onRecordMixpanelElementClickedEvent,
  onRemovePaymentMethod,
  onSubmitFormError,
  onSubmitFormSucceeded,
  organizationDisplayName,
  paymentMethod,
  paymentMethodBillingClientSecret,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const creditAmountInDollars = creditAmount / 100

  const formattedCreditAmount = `${intl.formatNumber(creditAmountInDollars, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`

  let tabs: Tab[] = [
    {
      label: intl.formatMessage(BillingMainContentMessages.billingInformationTabLabel),
      content: (
        <Suspense fallback={<LoadingPage />}>
          <BillingInformationNew
            automaticRechargeSettings={automaticRechargeSettings}
            editAutomaticRechargeSettingsPath={editAutomaticRechargeSettingsPath}
            isCreateBillingProfileSetupIntentsPending={isCreateBillingProfileSetupIntentsPending}
            isDisableAutoRechargeOptOutPending={isDisableAutoRechargeOptOutPending}
            isDisableAutoRechargeOptOutSuccessful={isDisableAutoRechargeOptOutSuccessful}
            isEditAutoRechargeSettingsPending={isEditAutoRechargeSettingsPending}
            isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
            isPaymentMethodVerificationInProgress={isPaymentMethodVerificationInProgress}
            isRemovePaymentMethodPending={isRemovePaymentMethodPending}
            isRemovePaymentMethodSuccessful={isRemovePaymentMethodSuccessful}
            manualCreditRequestAddCreditPath={manualCreditRequestAddCreditPath}
            onCancelAddPaymentMethod={onCancelAddPaymentMethod}
            onConfirmAutoRechargeOptOut={onConfirmAutoRechargeOptOut}
            onCreateBillingProfileSetupIntents={onCreateBillingProfileSetupIntents}
            onManageBilling={onManageBilling}
            onNavigateToBillingAutoRechargePage={onNavigateToBillingAutoRechargePage}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            onRemovePaymentMethod={onRemovePaymentMethod}
            onSubmitFormError={onSubmitFormError}
            onSubmitFormSucceeded={onSubmitFormSucceeded}
            paymentMethod={paymentMethod}
            paymentMethodBillingClientSecret={paymentMethodBillingClientSecret}
          />
        </Suspense>
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Billing Information tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.invoicesTabLabel),
      content: (
        <BillingInvoices
          billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
          organizationDisplayName={organizationDisplayName}
        />
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Invoices tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.usageTabLabel),
      content: <BillingUsage embedUrl={billingUsageEmbedUrl} organizationDisplayName={organizationDisplayName} />,
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Usage tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.creditsTabLabel),
      content: (
        <BillingCredits
          billingCreditEmbedUrl={billingCreditsEmbedUrl}
          organizationDisplayName={organizationDisplayName}
        />
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Credits tab'),
    },
  ]

  return (
    <div className="w-full">
      <div className="mb-5">
        <button onClick={() => navigate(-1)} className="text-blue-90 underline">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(BillingMainContentMessages.returnToPreviousPageLinkText)}
        </button>
      </div>
      <h1 className="mb-3 text-3xl font-bold text-neutral-100">
        {intl.formatMessage(BillingMainContentMessages.title, {
          organization_display_name: organizationDisplayName,
        })}
      </h1>
      <p className="mb-1 text-base text-neutral-100">
        {intl.formatMessage(BillingMainContentMessages.currentCreditAmount, {
          current_credit_amount: (
            <span onClick={() => setSelectedTabIndex(3)} className="cursor-pointer text-green-70 underline">
              {formattedCreditAmount}
            </span>
          ),
        })}
      </p>
      <p className="mb-1 text-base text-neutral-100">
        {intl.formatMessage(BillingMainContentMessages.visitDocumentationText, {
          visit_documentation_link: (
            <Link url="https://docs.salad.com/products/sce/container-groups/billing-and-pricing/billing">
              {intl.formatMessage(BillingMainContentMessages.visitDocumentationLinkText)}
            </Link>
          ),
        })}
      </p>
      <p className="mb-3 text-base text-neutral-100">
        {intl.formatMessage(BillingMainContentMessages.enterprisePricingText, {
          talk_to_sales: (
            <Link url="https://salad.com/sales-salad-gpu-cloud?utm_campaign=cloud&utm_medium=referral&utm_source=portal">
              {intl.formatMessage(BillingMainContentMessages.talkToSales)}
            </Link>
          ),
        })}
      </p>

      <Tabs tabs={tabs} selectedTabIndex={selectedTabIndex} onSelectedTabIndexChange={setSelectedTabIndex} />
    </div>
  )
}
