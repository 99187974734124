import { FunctionComponent } from 'react'
import { CreateOrganizationWizardProgressBar } from '../../components/CreateOrganizationWizard/CreateOrganizationWizardProgressBar'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models/CreateOrganizationWizardPageSteps'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateOrganizationWizardAddPaymentMainContent } from './components/CreateOrganizationWizardAddPaymentMainContent'

export interface CreateOrganizationWizardAddPaymentMethodPageProps {
  /** Client secret for Stripe setup intent */
  clientSecret: string | undefined
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
  /** A flag indicating if payment is in progress */
  isGetAddPaymentMethodSetupStatusPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A flag indicating if the component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback for handling form submission success */
  onSubmitStripeFormSucceeded: () => void
  /** A callback for handling form submission error */
  onSubmitStripeFormError: (message?: string) => void
}

export const CreateOrganizationWizardAddPaymentMethodPage: FunctionComponent<
  CreateOrganizationWizardAddPaymentMethodPageProps
> = ({
  clientSecret,
  isFreeTrial,
  isGetAddPaymentMethodSetupStatusPending,
  isLeftColumnOpen,
  isStorybookRender,
  onBack,
  onCloseLeftDrawer,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar
          currentPage={CreateOrganizationWizardPageSteps.AddPaymentMethod}
          isFreeTrial={isFreeTrial}
        />
      }
      MainColumn={
        <CreateOrganizationWizardAddPaymentMainContent
          clientSecret={clientSecret}
          isFreeTrial={isFreeTrial}
          isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
          isStorybookRender={isStorybookRender}
          onBack={onBack}
          onSubmitStripeFormError={onSubmitStripeFormError}
          onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
