import { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { determineTimeRange } from '../../../../pages/ContainerGroupDetails/utils'
import { DatePickerRangeMessages } from '../messages'
import { TimeOffsets, TimeRange } from '../models'

interface DateRangeTimesProps {
  /* The default end date to initialize the date picker with. */
  defaultEndDate: Date | null
  /* The default start date to initialize the date picker with. */
  defaultStartDate: Date | null
  /* The function to call when a time range is selected. */
  handleTimeRangeSelect(startDate: Date | null, endDate: Date | null, showCustomDatePicker: boolean): void
}

export const DateRangeTimes: FunctionComponent<DateRangeTimesProps> = ({
  defaultEndDate,
  defaultStartDate,
  handleTimeRangeSelect,
}) => {
  const intl = useIntl()
  const defaultTimeRange = determineTimeRange(defaultStartDate, defaultEndDate)
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange | undefined>(defaultTimeRange)

  const onTimeRangeButtonClick = (timeRange: TimeRange) => {
    const now = new Date()

    if (timeRange === TimeRange.Custom) {
      handleTimeRangeSelect(null, null, true)
      setSelectedTimeRange(timeRange)
    } else {
      const offset = TimeOffsets[timeRange]
      const newStartDate = offset ? new Date(now.getTime() - offset) : now
      handleTimeRangeSelect(newStartDate, now, false)
      setSelectedTimeRange(timeRange)
    }
  }

  const renderTimeRangeButton = (buttonLabel: string, timeRange: TimeRange) => (
    <button
      className={`flex-grow p-2 hover:bg-green-30 ${selectedTimeRange === timeRange ? 'bg-green-30' : ''}`}
      onClick={() => onTimeRangeButtonClick(timeRange)}
      type="button"
    >
      {buttonLabel}
    </button>
  )

  return (
    <div className="mb-5 flex justify-between rounded border">
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button5minutes), TimeRange.FiveMinutes)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button30minutes), TimeRange.ThirtyMinutes)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button1hour), TimeRange.OneHour)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button1day), TimeRange.OneDay)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button3days), TimeRange.ThreeDays)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.button7days), TimeRange.SevenDays)}
      <span className="border-r"></span>
      {renderTimeRangeButton(intl.formatMessage(DatePickerRangeMessages.buttonCustom), TimeRange.Custom)}
    </div>
  )
}
