import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Table } from '../../../../../components/block/Table'
import { TableColumnWidth, TableSortingOrder } from '../../../../../components/block/Table/models'
import { useFixedForm } from '../../../../../hooks'
import { formatToDateTime } from '../../../../../utils'
import { Button } from '../../../../Button'
import { DatePickerRange } from '../../../../forms/DatePickerRange'
import { SearchInput } from '../../../../search'
import { AutoRefreshButton } from '../AutoRefreshButton'
import { InstanceLogsMessages } from './messages'
import { InstanceLog, InstanceLogsFormValues, InstanceLogsQuery } from './models'
import { InstanceLogsField } from './models/'
import { configureInstanceLogsDefaultValues, convertFormDataToInstanceLogsQuery, onDownloadInstanceLogs } from './utils'

interface InstanceLogsProps {
  /** The display name of the container group */
  containerGroupDisplayName: string
  /** The initial form values to be passed in */
  defaultInstanceLogsFormValues?: Partial<InstanceLogsFormValues>
  /** The instance ID of the instance logs */
  instanceId: string
  /** The container logs array of items */
  instanceLogs: InstanceLog[]
  /** The flag to indicate if the query to get container instance logs is pending */
  isGetInstanceLogsPending: boolean
  /** The callback to start polling the instance logs */
  onStartPollingInstanceLogs: (instanceLogsFilters: InstanceLogsQuery) => void
  /** The callback that stops polling the instance logs. */
  onStopPollingInstanceLogs: () => void
}

export const InstanceLogs: FunctionComponent<InstanceLogsProps> = ({
  containerGroupDisplayName,
  defaultInstanceLogsFormValues,
  instanceId,
  instanceLogs,
  isGetInstanceLogsPending,
  onStartPollingInstanceLogs,
  onStopPollingInstanceLogs,
}) => {
  const intl = useIntl()
  const [isInstanceLogsDownloadLoading, setIsInstanceLogsDownloadLoading] = useState(false)
  const [isInstanceLogsPollingEnabled, setIsInstanceLogsPollingEnabled] = useState(true)
  const defaultFormValues = useMemo(
    () => configureInstanceLogsDefaultValues(defaultInstanceLogsFormValues),
    [defaultInstanceLogsFormValues],
  )
  const isInstanceLogsDownloadDisabled = instanceLogs.length === 0
  const { control, getValues, handleSubmit } = useFixedForm<InstanceLogsFormValues>({
    defaultValues: defaultFormValues,
    onSubmit: (data) => {
      queryInstanceLogs(data)
    },
  })

  const headers = [
    {
      key: InstanceLogsMessages.eventMessageTableHeader.id,
      label: intl.formatMessage(InstanceLogsMessages.eventMessageTableHeader),
      columnWidth: TableColumnWidth.Half,
    },
    {
      key: InstanceLogsMessages.timeTableHeader.id,
      label: intl.formatMessage(InstanceLogsMessages.timeTableHeader),
      initialSort: true,
      direction: TableSortingOrder.Ascending,
      columnWidth: TableColumnWidth.ThreeTwelfths,
    },
  ]

  const queryInstanceLogs = useCallback(
    (formData: InstanceLogsFormValues) => {
      const queryInstanceLogs = convertFormDataToInstanceLogsQuery(formData, instanceId)
      onStartPollingInstanceLogs(queryInstanceLogs)
    },
    [onStartPollingInstanceLogs, instanceId],
  )

  const formattedInstanceLogs = useMemo(() => {
    return instanceLogs?.map((log) => ({
      [InstanceLogsMessages.eventMessageTableHeader.id]: {
        value: log.eventMessage,
      },
      [InstanceLogsMessages.timeTableHeader.id]: {
        sortWith: new Date(log.time),
        value: formatToDateTime(intl, log.time as Date),
      },
    }))
  }, [instanceLogs, intl])

  const handleOnDownloadInstanceLogs = () =>
    onDownloadInstanceLogs(instanceLogs, containerGroupDisplayName, instanceId, setIsInstanceLogsDownloadLoading, intl)

  const handleToggleAutoRefresh = () => {
    if (isInstanceLogsPollingEnabled) {
      onStopPollingInstanceLogs()
    } else {
      const formData = getValues() as InstanceLogsFormValues
      const instanceLogsQuery = convertFormDataToInstanceLogsQuery(formData, instanceId)
      onStartPollingInstanceLogs(instanceLogsQuery)
    }
    setIsInstanceLogsPollingEnabled(!isInstanceLogsPollingEnabled)
  }

  useEffect(() => {
    queryInstanceLogs(defaultFormValues)
  }, [defaultFormValues, queryInstanceLogs])

  useEffect(() => {
    return () => {
      onStopPollingInstanceLogs()
    }
  }, [onStopPollingInstanceLogs])

  return (
    <>
      <div className="my-4 font-bold">{intl.formatMessage(InstanceLogsMessages.instanceLogsTitle)}</div>
      <div className="my-4">{intl.formatMessage(InstanceLogsMessages.instanceLogsDescriptionText)}</div>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 md:grid-cols-12">
        <div className="md:col-span-12">
          <Controller
            name={InstanceLogsField.FilterBy}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(InstanceLogsMessages.filterPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="md:col-span-5">
          <Controller
            name={InstanceLogsField.DateRange}
            control={control}
            render={({ field }) => {
              const { onChange, value } = field
              const { endDate, startDate } = value || {
                startDate: defaultFormValues.dateRange.startDate,
                endDate: defaultFormValues.dateRange.endDate,
              }

              return (
                <DatePickerRange
                  id="log-date-range"
                  isFullWidth
                  hasTimeSelect
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  onChange={(newStartDate, newEndDate) => onChange({ startDate: newStartDate, endDate: newEndDate })}
                />
              )
            }}
          />
        </div>
        <div className="md:col-span-2">
          <Button
            iconClassName="fa-solid fa-download"
            isDisabled={isInstanceLogsDownloadDisabled}
            isFullWidth
            isLoading={isInstanceLogsDownloadLoading}
            onClick={handleOnDownloadInstanceLogs}
            type="button"
            variant="green-filled-light"
          >
            {intl.formatMessage(InstanceLogsMessages.downloadButtonLabel)}
          </Button>
        </div>
        <div className="md:col-span-3">
          <AutoRefreshButton isEnabled={isInstanceLogsPollingEnabled} onToggleAutoRefresh={handleToggleAutoRefresh} />
        </div>
        <div className="md:col-span-2">
          <Button type="submit" isLoading={isGetInstanceLogsPending} isFullWidth>
            {intl.formatMessage(InstanceLogsMessages.queryButtonLabel)}
          </Button>
        </div>
      </form>
      <div className="py-2">
        <Table items={formattedInstanceLogs} isLoading={isGetInstanceLogsPending} headers={headers} />
      </div>
    </>
  )
}
