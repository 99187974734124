import classNames from 'classnames'
import { FunctionComponent, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { TableHeader } from './TableHeader'
import { CustomTableHeader, TableItem, TableSortingOrder } from './models'

import { Spinner } from '../../Spinner/Spinner'
import { TableMessages } from './messages/TableMessages'
import { sortItems } from './utils'

export interface TableProps {
  /** A flag that determines if the header text is centered. */
  isCentered?: boolean
  /** Headers need to have a key that directly corresponds to a key in the items array. */
  headers: CustomTableHeader[]
  /** IsLoading is a boolean that determines if the table is in a loading state. */
  isLoading?: boolean
  /** Items is the key:value array that displays the rows for the table. */
  items: TableItem[]
}

export interface SortConfigType {
  key: string
  direction: TableSortingOrder
}

export const Table: FunctionComponent<TableProps> = ({ headers, isCentered, isLoading, items }) => {
  const intl = useIntl()

  const [sortConfig, setSortConfig] = useState<SortConfigType | null>(() => {
    const initialSortHeader = headers.find((header) => header.initialSort)
    return initialSortHeader
      ? {
          key: initialSortHeader.key,
          direction: initialSortHeader.direction || TableSortingOrder.Ascending,
        }
      : null
  })

  const sortedItems = useMemo(() => {
    return sortItems(items, sortConfig)
  }, [items, sortConfig])

  const requestSort = (key: string) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig && prevConfig.key === key && prevConfig.direction === TableSortingOrder.Ascending
          ? TableSortingOrder.Descending
          : TableSortingOrder.Ascending,
    }))
  }

  return (
    <table className="w-full table-fixed">
      <TableHeader
        isCentered={isCentered}
        headers={headers.map((header) => ({
          ...header,
          sortingOrder: sortConfig && sortConfig.key === header.key ? sortConfig.direction : undefined,
          onClick: header.noSort ? undefined : () => requestSort(header.key),
        }))}
      />
      {items.length === 0 ? (
        <tbody>
          <tr>
            <td className="justify-center border-b border-b-neutral-30 px-4 py-2 text-center" colSpan={headers.length}>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                intl.formatMessage(TableMessages.noDataText)
              )}
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {sortedItems.map((item, index) => (
            <tr key={index}>
              {headers.map(({ columnWidth, key }) => {
                const { onClick, value } = item[key] || {}
                return (
                  <td
                    key={key}
                    className={classNames(
                      'justify-start break-words border-b border-b-neutral-30 px-4 py-2 text-left',
                      columnWidth,
                      isCentered && 'text-center',
                      onClick && 'cursor-pointer',
                    )}
                    onClick={onClick}
                  >
                    {value as React.ReactNode}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  )
}
