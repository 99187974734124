import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useEffect, useState, type FunctionComponent } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Turnstile from 'react-turnstile'
import { ButtonLink, Link } from '../../../../components/base'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import {
  handleTurnstileErrorToastNotification,
  loadTurnstileToken,
} from '../../../../components/TurnstileWrapper/utils'
import { useFixedForm } from '../../../../hooks'
import {
  getCreateOrganizationWizardConfigureCreditSettingsPagePath,
  getOrganizationsPagePath,
} from '../../../../routes/routes-utils'
import { OrganizationInformationMessages } from '../../messages'
import { OrganizationInformationFormValues } from '../../models'
import { useOrganizationInformationFormValidation } from '../../useOrganizationInformationFormValidation'

export interface CreateOrganizationWizardOrganizationInformationMainContentProps {
  /** The ID of the create organization wizard. */
  createOrganizationWizardId: string
  /** A flag indicating that the request to check if the organization name is available is pending. */
  isCheckOrganizationNameAvailabilityPending: boolean
  /** A flag indicating if the organization name is unavailable. */
  isOrganizationNameAvailable?: boolean
  /** The flag indicating that the request to reserve the organization name is pending. */
  isReserveOrganizationNamePending: boolean
  /** A flag indicating if the component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** A callback that when executed checks if the organization name is available. */
  onCheckOrganizationNameAvailability: (organizationName: string, turnstileSuccessToken: string) => void
  /** A callback that when executed reserves the organization name */
  onReserveOrganizationName: (organizationName: string) => void
  /** A callback that when executed resets the organization name availability flag. */
  onResetOrganizationNameAvailabilityFlag: () => void
  /** A callback that when executed tracks a Mixpanel event. */
  onTrackTurnstileMixpanelEvent: (eventName: string, turnstileErrorCode?: string) => void
  /** The default value for the organization reserved name. */
  organizationReservedNameDefaultValue?: string
}

export const CreateOrganizationWizardOrganizationInformationMainContent: FunctionComponent<
  CreateOrganizationWizardOrganizationInformationMainContentProps
> = ({
  createOrganizationWizardId,
  isCheckOrganizationNameAvailabilityPending,
  isOrganizationNameAvailable,
  isReserveOrganizationNamePending,
  isStorybookRender,
  onCheckOrganizationNameAvailability,
  onReserveOrganizationName,
  onResetOrganizationNameAvailabilityFlag,
  onTrackTurnstileMixpanelEvent,
  organizationReservedNameDefaultValue,
}) => {
  const intl = useIntl()
  const [isOrganizationNameUpdated, setIsOrganizationNameUpdated] = useState(false)
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false)
  const [hasShownTurnstileError, setHasShownTurnstileError] = useState(false)
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null)
  const [turnstileSuccessToken, setTurnstileSuccessToken] = useState('')

  const organizationInformationValidationScheme = useOrganizationInformationFormValidation()
  const { control, getFieldState, handleSubmit } = useFixedForm<OrganizationInformationFormValues>({
    resolver: yupResolver(organizationInformationValidationScheme),
    onSubmit: ({ organizationName }) => {
      onReserveOrganizationName(organizationName)
    },
    mode: 'onChange',
  })

  const organizationName = useWatch({ control, name: 'organizationName' })
  const isOrganizationNameInvalid = getFieldState('organizationName').invalid
  const isOrganizationNameDirty = getFieldState('organizationName').isDirty
  const isOrganizationReservedNameDefaultValueOrganizationName =
    (organizationReservedNameDefaultValue !== undefined && !isOrganizationNameDirty) ||
    (organizationName === organizationReservedNameDefaultValue && organizationReservedNameDefaultValue !== undefined)

  useEffect(() => {
    const handleCheckIfOrganizationNameIsAvailable = setTimeout(() => {
      if (
        isOrganizationNameDirty &&
        isOrganizationNameUpdated &&
        !isOrganizationReservedNameDefaultValueOrganizationName &&
        turnstileSuccessToken
      ) {
        setIsOrganizationNameUpdated(false)
        onCheckOrganizationNameAvailability(organizationName, turnstileSuccessToken)
      }
    }, 1000)

    return () => clearTimeout(handleCheckIfOrganizationNameIsAvailable)
  }, [
    isOrganizationNameDirty,
    isOrganizationNameInvalid,
    isOrganizationNameUpdated,
    isOrganizationReservedNameDefaultValueOrganizationName,
    onCheckOrganizationNameAvailability,
    organizationName,
    turnstileSuccessToken,
  ])

  const handleTurnstileError = (errorCode: string) => {
    if (!hasShownTurnstileError) {
      onTrackTurnstileMixpanelEvent('Turnstile Error', errorCode)
      handleTurnstileErrorToastNotification(errorCode, intl)
      setHasShownTurnstileError(true)
    }
  }

  const handleResetOrganizationNameAvailabilityFlag = () => {
    if (isOrganizationNameAvailable !== undefined) {
      onResetOrganizationNameAvailabilityFlag()
    }
  }
  useEffect(() => {
    const token = loadTurnstileToken(intl, onTrackTurnstileMixpanelEvent, isStorybookRender)
    setTurnstileToken(token)
  }, [intl, onTrackTurnstileMixpanelEvent, isStorybookRender])

  const shouldLoadTurnstile =
    turnstileToken && (getFieldState('organizationName').isDirty || !organizationReservedNameDefaultValue)

  return (
    <div className="relative size-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <div className="mb-6">
            <Link url={getOrganizationsPagePath()}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(OrganizationInformationMessages.returnToOrganizationsLinkText)}
            </Link>
          </div>
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(OrganizationInformationMessages.title)}</h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(OrganizationInformationMessages.description, {
              permanent_name: (
                <span className="font-bold">
                  {intl.formatMessage(OrganizationInformationMessages.descriptionPermanentNameText)}
                </span>
              ),
            })}
          </p>
          <form onSubmit={handleSubmit}>
            <h2 className="mb-2 text-xl font-bold">
              {intl.formatMessage(OrganizationInformationMessages.organizationDetailsHeader)}
            </h2>
            <p className="mb-8 text-base">
              {intl.formatMessage(OrganizationInformationMessages.organizationDetailsDescription)}
            </p>
            <Controller
              name="organizationName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={organizationReservedNameDefaultValue}
                  isLoading={isCheckOrganizationNameAvailabilityPending}
                  invalid={
                    fieldState.invalid ||
                    (!isOrganizationNameUpdated &&
                      isOrganizationNameAvailable !== undefined &&
                      isOrganizationNameAvailable === false)
                  }
                  onChange={(value) => {
                    handleResetOrganizationNameAvailabilityFlag()
                    setIsOrganizationNameUpdated(true)
                    field.onChange(value)
                  }}
                  isDisabled={isReserveOrganizationNamePending}
                  isHelperTextGreen={
                    isOrganizationNameAvailable || isOrganizationReservedNameDefaultValueOrganizationName
                  }
                  helperText={
                    isOrganizationNameUpdated && !isOrganizationReservedNameDefaultValueOrganizationName
                      ? intl.formatMessage(OrganizationInformationMessages.organizationNameHelperText)
                      : (isOrganizationNameAvailable && fieldState.error === undefined) ||
                          isOrganizationReservedNameDefaultValueOrganizationName
                        ? intl.formatMessage(OrganizationInformationMessages.organizationNameAvailable, {
                            organization_name: organizationName ?? organizationReservedNameDefaultValue,
                          })
                        : isOrganizationNameAvailable !== undefined &&
                            isOrganizationNameAvailable === false &&
                            fieldState.error === undefined
                          ? intl.formatMessage(OrganizationInformationMessages.organizationNameTakenErrorText, {
                              organization_name: organizationName,
                            })
                          : intl.formatMessage(OrganizationInformationMessages.organizationNameHelperText)
                  }
                  isFullWidth
                  label={intl.formatMessage(OrganizationInformationMessages.organizationNameFieldLabel)}
                />
              )}
            />

            {shouldLoadTurnstile && (
              <Turnstile
                className="mb-4"
                onError={handleTurnstileError}
                onSuccess={(token) => setTurnstileSuccessToken(token)}
                onVerify={() => {
                  setIsTurnstileVerified(true)
                }}
                sitekey={turnstileToken}
                theme="light"
              />
            )}
            {isOrganizationReservedNameDefaultValueOrganizationName ? (
              <div className="mb-4">
                <ButtonLink
                  isFullWidth
                  url={getCreateOrganizationWizardConfigureCreditSettingsPagePath(createOrganizationWizardId)}
                >
                  {intl.formatMessage(OrganizationInformationMessages.continueLabel)}
                </ButtonLink>
              </div>
            ) : (
              <div className="mb-4">
                <Button
                  type="submit"
                  isDisabled={!isOrganizationNameAvailable || !isTurnstileVerified || isOrganizationNameUpdated}
                  isLoading={isReserveOrganizationNamePending}
                  isFullWidth
                >
                  {intl.formatMessage(OrganizationInformationMessages.continueLabel)}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
