import { defineMessages } from 'react-intl'

export const ConfigureCreditSettingsMessages = defineMessages({
  autoRechargeTitle: {
    defaultMessage: 'Would you like to set up automatic recharge?',
    id: 'PF6/U9YA',
    description: 'Subtitle asking if automatic recharge should be set up.',
  },
  autoRechargeToggleLabel: {
    defaultMessage: 'Yes, automatically recharge my card when my credit balance falls below a threshold.',
    id: 'pw0S6Wn9',
    description: 'The label for the auto-recharge toggle button.',
  },
  backLabel: {
    defaultMessage: 'Back',
    id: 'faJYB4DJ',
    description: 'The label for the back button.',
  },
  continueLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  creditAmountInvalidMessage: {
    defaultMessage: 'Invalid credit amount',
    id: 'e2hEVURt',
    description: 'Message shown when the credit amount is invalid.',
  },
  creditAmountLabel: {
    defaultMessage: 'Select your credit amount',
    id: 'kmbESp7e',
    description: 'The label for the credit amount field.',
  },
  creditAmountRequiredMessage: {
    defaultMessage: 'You must enter an amount between $5 and $1,000 or $0 to opt for our free trial.',
    id: 'GDLZrqQ/',
    description: 'Message shown when the credit amount is required.',
  },
  creditOption20: {
    defaultMessage: '$20',
    id: 'WHjBL9Xd',
    description: 'Option for $20 in credit options.',
  },
  creditOption10: {
    defaultMessage: '$10',
    id: 'cPDVvfAj',
    description: 'Option for $10 in credit options.',
  },
  creditOption5: {
    defaultMessage: '$5',
    id: 'gDZM8s88',
    description: 'Option for $5 in credit options.',
  },
  creditOptionOther: {
    defaultMessage: 'Other',
    id: 'XyE1GoTZ',
    description: 'Option for "Other" in credit options.',
  },
  customAmountHelperText: {
    defaultMessage: 'Enter an amount between $5 and $1,000 or $0 to opt for our free trial.',
    id: 'O/FjMJ7+',
    description: 'The helper text for the custom amount field.',
  },
  customAmountLabel: {
    defaultMessage: 'Custom Credit Amount',
    id: '3k35fkmV',
    description: 'The label for the custom amount field.',
  },
  customAmountRequiredMessage: {
    defaultMessage: 'You must enter an amount between $5 and $1,000 or $0 to opt for our free trial.',
    id: 'g2P8Z7k4',
    description: 'Message shown when the custom credit amount is required.',
  },
  customAmountMinMessage: {
    defaultMessage: 'Minimum custom credit amount is $5',
    id: 'bewcDCBE',
    description: 'Message shown when custom credit amount is less than $5.',
  },
  customAmountMaxMessage: {
    defaultMessage: 'Maximum custom credit amount is $1000',
    id: 'XOM0jZsU',
    description: 'Message shown when custom credit amount is more than $1000.',
  },
  customAmountPositiveMessage: {
    defaultMessage: 'Custom credit amount must be a positive number',
    id: 'h9JNVIgk',
    description: 'Message shown when custom credit amount is not positive.',
  },
  description: {
    defaultMessage:
      'SaladCloud uses a prepaid credit system, billed by usage. To set up your organization, add credits below or skip to start with a free trial. A payment method is required on the next screen for verification. Optional auto-recharge is available to keep your balance positive and avoid shut offs if you exhaust your credits. {learn_more_link}.',
    id: 'U907714T',
    description: 'The description explaining the credit system.',
  },
  learnMoreLink: {
    defaultMessage: 'Learn more here',
    id: 'KY7W82RD',
    description: 'Text for the "learn more here" link.',
  },
  rechargeAmountHelperText: {
    defaultMessage: 'Enter an amount between $10 and $1,000.',
    id: 'vXiQYfpo',
    description: 'The helper text for the recharge amount field.',
  },
  rechargeAmountLabel: {
    defaultMessage: 'Bring credit balance back up to',
    id: 'vcWpOq3d',
    description: 'The label for the recharge amount field.',
  },
  rechargeThresholdGreaterThanRechargeAmountErrorMessage: {
    defaultMessage: 'The threshold amount must be less than the recharge amount.',
    id: 'HdZua0L7',
    description: 'The error message shown when the threshold amount is greater than the recharge amount.',
  },
  rechargeThresholdHelperText: {
    defaultMessage: 'Enter an amount between $5 and $995.',
    id: '595NNleq',
    description: 'The helper text for the recharge threshold field.',
  },
  rechargeThresholdLabel: {
    defaultMessage: 'When credit balance goes below',
    id: 'CDXey5iX',
    description: 'The label for the recharge threshold field.',
  },
  returnToOrganizationsLinkText: {
    defaultMessage: 'Return to Your Organizations',
    id: 'IFJw1CXp',
    description: 'The text for the link that that returns the user back to the Organizations page.',
  },
  skipToFreeTrialButtonLabel: {
    defaultMessage: 'Skip to free trial',
    id: 'c7xgjuWR',
    description: 'The label for the skip to free trial button.',
  },
  title: {
    defaultMessage: 'Configure Credit Settings',
    id: 'avfB/KHg',
    description: 'The title for the Configure Credit Settings page.',
  },
})
