import { defineMessages } from 'react-intl'

export const GetSystemEventLogsDownloadMessages = defineMessages({
  body: {
    defaultMessage: `You have successfully started downloading your system event logs.`,
    id: 'JFxLKU9U',
    description: `The toast notification body shown when the user starts downloading system event logs.`,
  },
  title: {
    defaultMessage: 'Download started',
    id: 'h9KYUZCf',
    description: "The toast notification title shown when we start downloading the user's system event logs.",
  },
})
