import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../Button'
import { AutoRefreshButtonMessages } from './messages'

interface AutoRefreshButtonProps {
  /** Indicates if the auto refresh button is in an enabled state */
  isEnabled: boolean
  /** The callback to toggle the auto refresh */
  onToggleAutoRefresh: () => void
}

export const AutoRefreshButton: FunctionComponent<AutoRefreshButtonProps> = ({ isEnabled, onToggleAutoRefresh }) => {
  const intl = useIntl()
  return (
    <Button
      onClick={onToggleAutoRefresh}
      isFullWidth
      type="button"
      variant="green-filled-light"
      iconClassName={`fa-solid ${isEnabled ? `fa-pause` : `fa-play`}`}
    >
      {isEnabled
        ? intl.formatMessage(AutoRefreshButtonMessages.pauseButtonLabel)
        : intl.formatMessage(AutoRefreshButtonMessages.resumeButtonLabel)}
    </Button>
  )
}
