import { ContainerGroupInstance } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { InstanceDetailsMainContent } from '../../components/instances/InstanceDetailsMainContent/InstanceDetailsMainContent'
import {
  InstanceLog,
  InstanceLogsFormValues,
  InstanceLogsQuery,
} from '../../components/instances/InstanceDetailsMainContent/components/InstanceLogs/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'

export interface ContainerGroupInstanceDetailsPageProps {
  /** The display name of the container group */
  containerGroupDisplayName: string
  /** The container group instance */
  containerGroupInstance?: ContainerGroupInstance
  /** The unique identifier of the container group instance. This is used to identify the container group instance in the */
  containerGroupInstanceId: string
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The initial form values to be passed in */
  defaultInstanceLogsFormValues?: Partial<InstanceLogsFormValues>
  /** The container logs array of items */
  instanceLogs: InstanceLog[]
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag to indicate if the query to get container instance logs is pending */
  isGetInstanceLogsPending: boolean
  /** The flag indicating if the instance logs are ready */
  isInstanceLogsReady?: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if the Reallocate button is submitting */
  isReallocateContainerGroupInstancePending: boolean
  /** The flag indicating if the Recreate button is submitting */
  isRecreateContainerGroupInstancePending: boolean
  /** The flag indicating if the Restart button is submitting */
  isRestartContainerGroupInstancePending: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectDisplayName?: string) => void
  /** The handler for reallocate instance. */
  onReallocate: () => void
  /** The handler for recreate instance. */
  onRecreate: () => void
  /** The handler for restart instance. */
  onRestart: () => void
  /** The callback to start polling the instance logs */
  onStartPollingInstanceLogs: (instanceLogsFilters: InstanceLogsQuery) => void
  /** The callback that stops polling the instance logs. */
  onStopPollingInstanceLogs: () => void
  /** The handler for viewing the container group logs of the selected container group's machineId */
  onViewContainerGroupLogs: () => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The terminal websocket url */
  terminalWebSocketUrl?: string
}

export const ContainerGroupInstanceDetailsPage: FunctionComponent<ContainerGroupInstanceDetailsPageProps> = ({
  containerGroupDisplayName,
  containerGroupInstance,
  containerGroupInstanceId,
  currentOrganization,
  currentProject,
  defaultInstanceLogsFormValues,
  instanceLogs,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isGetInstanceLogsPending,
  isInstanceLogsReady,
  isLeftColumnOpen,
  isReallocateContainerGroupInstancePending,
  isRecreateContainerGroupInstancePending,
  isRestartContainerGroupInstancePending,
  onCloseLeftDrawer,
  onDeleteProject,
  onProjectChange,
  onReallocate,
  onRecreate,
  onRestart,
  onStartPollingInstanceLogs,
  onStopPollingInstanceLogs,
  onViewContainerGroupLogs,
  projects,
  terminalWebSocketUrl,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <InstanceDetailsMainContent
          containerGroupDisplayName={containerGroupDisplayName}
          defaultInstanceLogsFormValues={defaultInstanceLogsFormValues}
          deploymentDisplayName={containerGroupDisplayName}
          instance={containerGroupInstance}
          instanceId={containerGroupInstanceId}
          instanceLogs={instanceLogs}
          isGetInstanceLogsPending={isGetInstanceLogsPending}
          isInstanceLogsReady={isInstanceLogsReady}
          isReallocateInstancePending={isReallocateContainerGroupInstancePending}
          isRecreateInstancePending={isRecreateContainerGroupInstancePending}
          isRestartInstancePending={isRestartContainerGroupInstancePending}
          onReallocate={onReallocate}
          onRecreate={onRecreate}
          onRestart={onRestart}
          onStartPollingInstanceLogs={onStartPollingInstanceLogs}
          onStopPollingInstanceLogs={onStopPollingInstanceLogs}
          onViewContainerGroupLogs={onViewContainerGroupLogs}
          terminalWebSocketUrl={terminalWebSocketUrl}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
