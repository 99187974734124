import { catchError, concat, defer, delay, filter, from, mergeMap, of, repeat, switchMap, takeUntil, timer } from 'rxjs'
import { ContainerGroupsAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import {
  containerGroupInstanceAdded,
  containerGroupInstanceRemoved,
  getContainerGroupInstanceDetailsPageData,
  startPollingForContainerGroupInstance,
  stopPollingForContainerGroupInstance,
} from '../features/containerGroupInstances/containerGroupInstancesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getUnableToGetContainerGroupInstanceGeneralErrorContent } from '../notifications/clientToastNotificationContent/instanceDetails'
import type { AppEpic } from '../store'

export const onGetContainerGroupInstanceDetailsPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getContainerGroupInstanceDetailsPageData.match),
    switchMap(({ payload: { containerGroupName, instanceId, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupInstanceDetailsPageData', status: 'pending' })),
        from(
          Promise.all([
            ContainerGroupsAPI.getContainerGroupInstanceByInstanceId({
              organizationName,
              containerGroupName,
              projectName,
              containerGroupInstanceId: instanceId,
            }),
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([instance, organizationResponse, projectsResponse]) => {
            return concat(
              of(
                containerGroupInstanceAdded({
                  containerGroupName,
                  projectName,
                  organizationName,
                  instance,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName,
                  projects: projectsResponse.items,
                }),
                startPollingForContainerGroupInstance({
                  containerGroupName,
                  instanceId,
                  organizationName,
                  projectName,
                }),
                setRequestStatus({ request: 'getContainerGroupInstanceDetailsPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupInstanceDetailsPageData', status: 'idle' })).pipe(
                delay(1),
              ),
            )
          }),
          catchError(() =>
            concat(
              of(
                showToastNotification(getUnableToGetContainerGroupInstanceGeneralErrorContent(intl)),
                setRequestStatus({ request: 'getContainerGroupInstanceDetailsPageData', status: 'failed' }),
                containerGroupInstanceRemoved({
                  containerGroupName,
                  organizationName,
                  projectName,
                  instanceId,
                }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupInstanceDetailsPageData', status: 'idle' })).pipe(
                delay(1),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const onPollContainerGroupInstanceDetails: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(startPollingForContainerGroupInstance.match),
    switchMap(({ payload: { containerGroupName, instanceId, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'pollContainerGroupInstance', status: 'pending' })),
        defer(() =>
          from(
            ContainerGroupsAPI.getContainerGroupInstanceByInstanceId({
              organizationName,
              containerGroupName,
              projectName,
              containerGroupInstanceId: instanceId,
            }),
          ),
        ).pipe(
          repeat({
            delay: () => {
              const fiveSecondsInMS = 5000
              return timer(fiveSecondsInMS)
            },
          }),
          mergeMap((instance) =>
            concat(
              of(
                containerGroupInstanceAdded({
                  containerGroupName,
                  projectName,
                  organizationName,
                  instance,
                }),
                setRequestStatus({ request: 'pollContainerGroupInstance', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'pollContainerGroupInstance', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                showToastNotification(getUnableToGetContainerGroupInstanceGeneralErrorContent(intl)),
                setRequestStatus({ request: 'pollContainerGroupInstance', status: 'failed' }),
                containerGroupInstanceRemoved({
                  containerGroupName,
                  organizationName,
                  projectName,
                  instanceId,
                }),
                stopPollingForContainerGroupInstance(),
              ),
              of(setRequestStatus({ request: 'pollContainerGroupInstance', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingForContainerGroupInstance.match))),
        ),
      ),
    ),
  )
}
