import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InstanceLogsQuery } from '../../components/instances/InstanceDetailsMainContent/components/InstanceLogs/models'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { setDefaultContainerGroupFormValues } from '../../features/containerGroupDetail/containerGroupDetailSlice'
import { selectContainerGroupInstanceLogs } from '../../features/containerGroupInstanceLogs/containerGroupInstanceLogsSelectors'
import {
  startPollingContainerGroupInstanceLogs,
  stopPollingContainerGroupInstanceLogs,
} from '../../features/containerGroupInstanceLogs/containerGroupInstanceLogsSlice'
import { configureContainerGroupInstanceLogsEntityId } from '../../features/containerGroupInstanceLogs/utils'
import {
  selectIsReallocateContainerGroupInstanceStatusPending,
  selectIsRecreateContainerGroupInstanceStatusPending,
  selectIsRestartContainerGroupInstanceStatusPending,
} from '../../features/containerGroupInstanceTable/containerGroupInstanceTableSelectors'
import { selectContainerGroupInstance } from '../../features/containerGroupInstances/containerGroupInstancesSelectors'
import {
  getContainerGroupInstanceDetailsPageData,
  reallocateContainerGroupInstance,
  recreateContainerGroupInstance,
  restartContainerGroupInstance,
  stopPollingForContainerGroupInstance,
} from '../../features/containerGroupInstances/containerGroupInstancesSlice'
import { configuresContainerGroupsInstancesEntityId } from '../../features/containerGroupInstances/utils'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import {
  selectRequestStatus,
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { selectTerminalWebSocketUrl } from '../../features/terminal/terminalSelectors'
import { fetchTerminalToken } from '../../features/terminal/terminalSlice'
import { containerGroupInstanceDetailsPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupDetailsPagePath, getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { ContainerGroupInstanceDetailsPage } from './ContainerGroupInstanceDetailsPage'

export const ConnectedContainerGroupInstanceDetailsPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { containerGroupName = '', instanceId = '', organizationName = '', projectName = '' } = useParams()
  const [isInitialPageLoadSuccessful, setIsInitialPageLoadSuccessful] = useState(false)
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const terminalWebSocketUrl = useAppSelector(selectTerminalWebSocketUrl)
  const containerGroupInstance = useAppSelector((state) =>
    selectContainerGroupInstance(
      state,
      configuresContainerGroupsInstancesEntityId(organizationName, projectName, containerGroupName),
      instanceId,
    ),
  )
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const isGetContainerGroupInstanceDetailsPageLoadSuccessful = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'getContainerGroupInstanceDetailsPageData'),
  )
  const isReallocateContainerGroupInstancePending = useAppSelector((state) =>
    selectIsReallocateContainerGroupInstanceStatusPending(state, instanceId),
  )
  const isRecreateContainerGroupInstancePending = useAppSelector((state) =>
    selectIsRecreateContainerGroupInstanceStatusPending(state, instanceId),
  )
  const isRestartContainerGroupInstancePending = useAppSelector((state) =>
    selectIsRestartContainerGroupInstanceStatusPending(state, instanceId),
  )
  const isGetInstanceLogsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'pollContainerGroupInstanceLogs'),
  )
  const containerGroupInstanceLogs = useAppSelector((state) =>
    selectContainerGroupInstanceLogs(
      state.containerGroupInstanceLogsSlice,
      configureContainerGroupInstanceLogsEntityId(containerGroupName, organizationName, projectName, instanceId),
    ),
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleProjectChange = useCallback(
    (projectName?: string) => {
      if (projectName) {
        navigate(getContainerGroupsPagePath(organizationName, projectName))
      }
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onReallocateContainerGroupInstance = useCallback(() => {
    dispatch(
      reallocateContainerGroupInstance({
        organizationName: organizationName,
        projectName: projectName,
        containerGroupName: containerGroupName,
        instanceId: instanceId,
        redirectPath: getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName),
      }),
    )
  }, [dispatch, organizationName, projectName, containerGroupName, instanceId])

  const onRecreateContainerGroupInstance = useCallback(() => {
    dispatch(
      recreateContainerGroupInstance({
        organizationName: organizationName,
        projectName: projectName,
        containerGroupName: containerGroupName,
        instanceId: instanceId,
        redirectPath: getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName),
      }),
    )
  }, [dispatch, organizationName, projectName, containerGroupName, instanceId])

  const onRestartContainerGroupInstance = useCallback(() => {
    dispatch(
      restartContainerGroupInstance({
        organizationName: organizationName,
        projectName: projectName,
        containerGroupName: containerGroupName,
        instanceId: instanceId,
        redirectPath: getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName),
      }),
    )
  }, [dispatch, organizationName, projectName, containerGroupName, instanceId])

  const onViewContainerGroupLogs = useCallback(() => {
    navigate(getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName))
    dispatch(setDefaultContainerGroupFormValues({ formValues: { machineId: instanceId }, tabValue: 3 }))
  }, [navigate, organizationName, projectName, containerGroupName, dispatch, instanceId])

  const handleFetchTerminalToken = useCallback(() => {
    dispatch(
      fetchTerminalToken({
        machineId: instanceId,
        organizationName,
        projectName,
        containerGroupName,
      }),
    )
  }, [containerGroupName, dispatch, instanceId, organizationName, projectName])

  const handleGetContainerGroupInstanceDetailsPageData = useCallback(() => {
    dispatch(
      getContainerGroupInstanceDetailsPageData({
        containerGroupName,
        organizationName,
        projectName,
        instanceId,
      }),
    )
  }, [dispatch, containerGroupName, organizationName, projectName, instanceId])

  useEffect(() => {
    !isInitialPageLoadSuccessful &&
      isGetContainerGroupInstanceDetailsPageLoadSuccessful &&
      setIsInitialPageLoadSuccessful(true)
  }, [
    deleteProjectRequestStatus,
    handleProjectChange,
    isGetContainerGroupInstanceDetailsPageLoadSuccessful,
    isInitialPageLoadSuccessful,
  ])

  useEffect(() => {
    handleGetContainerGroupInstanceDetailsPageData()
    handleFetchTerminalToken()
    dispatch(
      trackMixpanelPageViewedEvent({
        path: containerGroupInstanceDetailsPageRoutePath,
        organizationName,
        projectName,
        instanceId,
        resourceName: containerGroupName,
      }),
    )
  }, [
    containerGroupName,
    dispatch,
    handleFetchTerminalToken,
    handleGetContainerGroupInstanceDetailsPageData,
    instanceId,
    organizationName,
    projectName,
  ])

  const handleStartPollingInstanceLogs = useCallback(
    (instanceLogFilters: InstanceLogsQuery) => {
      dispatch(
        startPollingContainerGroupInstanceLogs({
          containerGroupName,
          organizationName,
          projectName,
          instanceLogFilters: {
            ...instanceLogFilters,
            instanceId,
          },
        }),
      )
    },
    [containerGroupName, dispatch, instanceId, organizationName, projectName],
  )

  const handleStopPollingSystemEventLogs = useCallback(() => {
    dispatch(stopPollingContainerGroupInstanceLogs())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForContainerGroupInstance())
      dispatch(stopPollingContainerGroupInstanceLogs())
    }
  }, [dispatch])

  const isInstanceLogsReady = false

  return !isInitialPageLoadSuccessful ? (
    <LoadingPage />
  ) : (
    <ContainerGroupInstanceDetailsPage
      containerGroupDisplayName={containerGroupName}
      containerGroupInstance={containerGroupInstance}
      containerGroupInstanceId={instanceId}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      instanceLogs={containerGroupInstanceLogs}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isGetInstanceLogsPending={isGetInstanceLogsPending}
      isInstanceLogsReady={isInstanceLogsReady}
      isLeftColumnOpen={isLeftColumnOpen}
      isReallocateContainerGroupInstancePending={isReallocateContainerGroupInstancePending}
      isRecreateContainerGroupInstancePending={isRecreateContainerGroupInstancePending}
      isRestartContainerGroupInstancePending={isRestartContainerGroupInstancePending}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      onReallocate={onReallocateContainerGroupInstance}
      onRecreate={onRecreateContainerGroupInstance}
      onRestart={onRestartContainerGroupInstance}
      onStartPollingInstanceLogs={handleStartPollingInstanceLogs}
      onStopPollingInstanceLogs={handleStopPollingSystemEventLogs}
      onViewContainerGroupLogs={onViewContainerGroupLogs}
      projects={projectSelectOptions}
      terminalWebSocketUrl={terminalWebSocketUrl}
    />
  )
}
