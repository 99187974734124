import { IntlShape } from 'react-intl'
import type { RadioCardOptions } from '../../../../components/forms/RadioCards/models'
import { ConfigureCreditSettingsMessages } from '../../messages'
import { CreditSettingsFormValues } from '../../models'

/**
 * Returns a flag indicating if the user is currently configured to do a free trial, bypassing setting up any credit
 * configuration.
 *
 * @param defaultValues The default form values.
 */
export const getFreeTrialPathFlagFromDefaultValues = (defaultValues?: CreditSettingsFormValues) => {
  const freeTrialInitialCreditValues = defaultValues?.initialCredit == null || defaultValues?.initialCredit === 0
  return freeTrialInitialCreditValues && !defaultValues?.automaticRecharge
}

/**
 * Retrieves the radio card options for the Configure Credit Settings form.
 *
 * @param intl The react-intl object.
 */
export const getConfigureCreditSettingsCardOptions = (intl: IntlShape): RadioCardOptions => [
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption5)}
      </p>
    ),
    value: 5,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption10)}
      </p>
    ),
    value: 10,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption20)}
      </p>
    ),
    value: 20,
  },
  {
    content: (
      <p className="text-center font-bold text-green-80">
        {intl.formatMessage(ConfigureCreditSettingsMessages.creditOptionOther)}
      </p>
    ),
    value: 'other',
  },
]

/**
 * Returns a flag indicating if the current form value for initial credit is a value that indicates the user has opted
 * for a free trial. Note, this is only used for the initial credit field, not the automatic recharge fields that also
 * must not be set in order to select the free trial option.
 *
 * @param formValues The form values.
 */
export const isInitialCreditValueMeetFreeTrialRequirements = (initialCredit?: string | number): boolean => {
  return initialCredit === '0' || initialCredit === 0
}

/**
 * Returns a flag indicating if the initial credit value matches one of our preselected options a user could choose
 * from.
 *
 * @param initialCredit The form values.
 */
export const setInitialCreditRadioCardOption = (initialCredit?: string | number): string | number => {
  const initialCreditValue = Number(initialCredit)
  if (initialCreditValue === 5 || initialCreditValue === 10 || initialCreditValue === 20) {
    return initialCreditValue
  }

  return 'other'
}
