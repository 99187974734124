import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { RadioCardsTemp } from '../../../../components/forms/RadioCards/RadioCardsTemp'
import { TextField } from '../../../../components/TextField'
import { useFixedForm } from '../../../../hooks'
import { getOrganizationsPagePath } from '../../../../routes/routes-utils'
import { ConfigureCreditSettingsMessages } from '../../messages'
import { CreditSettingsFormValues } from '../../models'
import { useCreditSettingsFormValidation } from '../../useCreditSettingsFormValidation'
import {
  getConfigureCreditSettingsCardOptions,
  getFreeTrialPathFlagFromDefaultValues,
  isInitialCreditValueMeetFreeTrialRequirements,
  setInitialCreditRadioCardOption,
} from './utils'

export interface CreateOrganizationWizardConfigureCreditSettingsMainContentProps {
  /** The default values for the credit settings form. */
  creditSettingsDefaultValues?: CreditSettingsFormValues
  /** The flag indicating that the request to edit the credit settings is pending. */
  isEditCreateOrganizationWizardPending: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** A callback that when executed configures the credit settings */
  onConfigureCreditSettings: (values: CreditSettingsFormValues) => void
}

export const CreateOrganizationWizardConfigureCreditSettingsMainContent: FunctionComponent<
  CreateOrganizationWizardConfigureCreditSettingsMainContentProps
> = ({ creditSettingsDefaultValues, isEditCreateOrganizationWizardPending, onBack, onConfigureCreditSettings }) => {
  const intl = useIntl()
  const { control, formState, handleSubmit, reset, setValue, trigger, watch } = useFixedForm<CreditSettingsFormValues>({
    resolver: yupResolver(useCreditSettingsFormValidation()),
    onSubmit: (values) => onConfigureCreditSettings(values),
    defaultValues: {
      automaticRecharge: creditSettingsDefaultValues?.automaticRecharge ?? false,
      initialCredit: creditSettingsDefaultValues?.initialCredit
        ? Number(creditSettingsDefaultValues?.initialCredit) / 100
        : 0,
      automaticRechargeAmount: creditSettingsDefaultValues?.automaticRechargeAmount
        ? Number(creditSettingsDefaultValues?.automaticRechargeAmount) / 100
        : undefined,
      automaticRechargeThreshold: creditSettingsDefaultValues?.automaticRechargeThreshold
        ? Number(creditSettingsDefaultValues?.automaticRechargeThreshold) / 100
        : undefined,
    },
    mode: 'onChange',
  })
  const defaultOption = setInitialCreditRadioCardOption(creditSettingsDefaultValues?.initialCredit)
  const [initialCreditOption, setInitialCreditOption] = useState<string | number>(defaultOption)
  const [isFreeTrialPath, setIsFreeTrialPath] = useState(
    getFreeTrialPathFlagFromDefaultValues(creditSettingsDefaultValues),
  )

  const autoRechargeEnabled = watch('automaticRecharge')

  const initialCredit = watch('initialCredit')

  const radioCardOptions = getConfigureCreditSettingsCardOptions(intl)

  const handleCreditAmountSelection = useCallback(
    (value?: string) => {
      if (value === 'other') {
        if (initialCredit === '') {
          setValue('initialCredit', 0)
          trigger('initialCredit')
        }
        setInitialCreditOption('other')
      } else {
        const initialCreditValue = setInitialCreditRadioCardOption(value)
        setValue('initialCredit', initialCreditValue)
        trigger('initialCredit')
        setInitialCreditOption(initialCreditValue)
      }
    },
    [initialCredit, setValue, trigger],
  )

  const handleCheckboxChange = (checked: boolean) => {
    setValue('automaticRecharge', checked)
    if (!checked) {
      reset({
        automaticRecharge: false,
        automaticRechargeAmount: null,
        automaticRechargeThreshold: null,
        initialCredit: initialCredit === 'other' ? '' : initialCredit,
      })
    }

    setValue('automaticRechargeAmount', creditSettingsDefaultValues?.automaticRechargeAmount)
    setValue('automaticRechargeThreshold', creditSettingsDefaultValues?.automaticRechargeThreshold)
    trigger('initialCredit')
  }

  useEffect(() => {
    const isSkipToFreeTrialPath = isInitialCreditValueMeetFreeTrialRequirements(initialCredit) && !autoRechargeEnabled

    setIsFreeTrialPath(isSkipToFreeTrialPath)
  }, [autoRechargeEnabled, initialCredit])

  return (
    <div className="relative size-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <div className="mb-6">
            <Link url={getOrganizationsPagePath()}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(ConfigureCreditSettingsMessages.returnToOrganizationsLinkText)}
            </Link>
          </div>
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(ConfigureCreditSettingsMessages.title)}</h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(ConfigureCreditSettingsMessages.description, {
              learn_more_link: (
                <Link url="https://docs.salad.com/products/sce/billing">
                  {intl.formatMessage(ConfigureCreditSettingsMessages.learnMoreLink)}
                </Link>
              ),
            })}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <Controller
                name="initialCredit"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <div className="mb-4 flex gap-4">
                      <RadioCardsTemp
                        cards={radioCardOptions}
                        value={initialCreditOption}
                        hasNoMarginBottom
                        isInline
                        label={intl.formatMessage(ConfigureCreditSettingsMessages.creditAmountLabel)}
                        name="initialCredit"
                        onChange={(value) => handleCreditAmountSelection(value)}
                      />
                    </div>
                    <div className="mb-4 flex gap-4">
                      <TextField
                        {...field}
                        {...fieldState}
                        defaultValue={field.value}
                        hasNoDefaultHeight
                        hasNoDefaultMarginBottom
                        helperText={
                          fieldState.error?.message ??
                          intl.formatMessage(ConfigureCreditSettingsMessages.customAmountHelperText)
                        }
                        hideLabelText
                        isFullWidth
                        label={intl.formatMessage(ConfigureCreditSettingsMessages.customAmountLabel)}
                        leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                        onChange={(value) => {
                          field.onChange(value)
                          if (value) {
                            const numericValue = Number(value)
                            const updatedValue = isNaN(numericValue) ? undefined : numericValue
                            const option = setInitialCreditRadioCardOption(updatedValue)
                            setInitialCreditOption(option)
                          }
                        }}
                        placeholder={initialCredit !== 'other' ? initialCredit : undefined}
                        type="number"
                      />
                    </div>
                  </>
                )}
              />
            </div>

            <h2 className="mb-4 text-xl font-bold">
              {intl.formatMessage(ConfigureCreditSettingsMessages.autoRechargeTitle)}
            </h2>

            <div className="mb-8">
              <Controller
                name="automaticRecharge"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    isChecked={field.value}
                    onChange={(checked) => handleCheckboxChange(checked)}
                    label={intl.formatMessage(ConfigureCreditSettingsMessages.autoRechargeToggleLabel)}
                    name="automaticRecharge"
                    id="automaticRecharge"
                  />
                )}
              />
            </div>

            <Controller
              name="automaticRechargeThreshold"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={field.value}
                  helperText={
                    fieldState.error?.message ??
                    intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText)
                  }
                  isDisabled={!autoRechargeEnabled}
                  isFullWidth
                  label={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdLabel)}
                  leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                  type="number"
                />
              )}
            />
            <Controller
              name="automaticRechargeAmount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={field.value}
                  helperText={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText)}
                  isDisabled={!autoRechargeEnabled}
                  isFullWidth
                  label={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountLabel)}
                  leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                  onChange={(value) => {
                    field.onChange(value)
                    trigger('automaticRechargeThreshold')
                  }}
                  type="number"
                />
              )}
            />

            <div className="my-6 flex w-full gap-12">
              <div className="flex-1">
                <Button
                  variant="green-outlined"
                  type="button"
                  isDisabled={isEditCreateOrganizationWizardPending}
                  onClick={onBack}
                  isFullWidth
                >
                  {intl.formatMessage(ConfigureCreditSettingsMessages.backLabel)}
                </Button>
              </div>

              <div className="flex-1">
                <Button
                  type="submit"
                  isLoading={isEditCreateOrganizationWizardPending}
                  isDisabled={!formState.isValid}
                  isFullWidth
                >
                  {isFreeTrialPath
                    ? intl.formatMessage(ConfigureCreditSettingsMessages.skipToFreeTrialButtonLabel)
                    : intl.formatMessage(ConfigureCreditSettingsMessages.continueLabel)}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
