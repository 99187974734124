import { FunctionComponent } from 'react'
import { CreateOrganizationWizardProgressBar } from '../../components/CreateOrganizationWizard/CreateOrganizationWizardProgressBar'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateOrganizationWizardConfigureCreditSettingsMainContent } from './components/CreateOrganizationWizardConfigureCreditSettingsMainContent'
import { CreditSettingsFormValues } from './models'

export interface CreateOrganizationWizardConfigureCreditSettingsPageProps {
  /** The default values for the credit settings form. */
  creditSettingsDefaultValues?: CreditSettingsFormValues
  /** The flag indicating that the request to edit the credit settings is pending. */
  isEditCreateOrganizationWizardPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed configures the credit settings */
  onConfigureCreditSettings: (values: CreditSettingsFormValues) => void
}

export const CreateOrganizationWizardConfigureCreditSettingsPage: FunctionComponent<
  CreateOrganizationWizardConfigureCreditSettingsPageProps
> = ({
  creditSettingsDefaultValues,
  isEditCreateOrganizationWizardPending,
  isLeftColumnOpen,
  onBack,
  onCloseLeftDrawer,
  onConfigureCreditSettings,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar currentPage={CreateOrganizationWizardPageSteps.ConfigureCreditSettings} />
      }
      MainColumn={
        <CreateOrganizationWizardConfigureCreditSettingsMainContent
          creditSettingsDefaultValues={creditSettingsDefaultValues}
          isEditCreateOrganizationWizardPending={isEditCreateOrganizationWizardPending}
          onBack={onBack}
          onConfigureCreditSettings={onConfigureCreditSettings}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
