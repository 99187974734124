import { defineMessages } from 'react-intl'

export const GetInstanceLogsDownloadErrorContentMessages = defineMessages({
  body: {
    defaultMessage: `Please try again. If the issue persists, {contact_support}.`,
    id: 'H0SDhPCy',
    description:
      'The description shown in a toast notification message shown when we encounter an error downloading the instance logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Tj48Q4p4',
    description: 'The link text shown in a toast notification message.',
  },
  title: {
    defaultMessage: 'Unable to download instance logs',
    id: 'x8IdOq3Z',
    description: 'The toast notification title shown when we encounter an error downloading the instance logs.',
  },
})
