import { defineMessages } from 'react-intl'

export const BillingMainContentMessages = defineMessages({
  billingInformationTabLabel: {
    defaultMessage: 'Billing Information',
    id: 'WHVYREor',
    description: 'The label for the billing information tab.',
  },
  creditsTabLabel: {
    defaultMessage: 'Credits',
    id: 'Kek9ujyL',
    description: 'The label for the credits tab.',
  },
  currentCreditAmount: {
    defaultMessage: 'Current Credit Amount: {current_credit_amount}',
    id: 'd082K3uh',
    description: 'The label for the current credit amount.',
  },
  enterprisePricingText: {
    defaultMessage: 'Interested in Enterprise Pricing? {talk_to_sales}',
    id: 'hMTS/EG0',
    description: 'The text for prompting users to contact sales for enterprise pricing.',
  },
  invoicesTabLabel: {
    defaultMessage: 'Invoices',
    id: 'pbgv+Z8t',
    description: 'The label for the invoices tab.',
  },
  returnToPreviousPageLinkText: {
    defaultMessage: 'Return to previous page',
    id: 'BQAF8CIV',
    description: 'The text for the link that returns the user back to the previous page they were viewing.',
  },
  talkToSales: {
    defaultMessage: 'Talk to Sales',
    id: 'C+zWAiDz',
    description: 'The text for the Talk to Sales link.',
  },
  title: {
    defaultMessage: '{organization_display_name} Billing',
    id: '4QWdjfQQ',
    description: 'The title of for the Billing page.',
  },
  usageTabLabel: {
    defaultMessage: 'Usage',
    id: 'DBpRd/4g',
    description: 'The label for the usage tab.',
  },
  visitDocumentationLinkText: {
    defaultMessage: 'visit our documentation',
    id: '0Ysq+DMj',
    description: 'The text for the link that takes the user to the documentation page.',
  },
  visitDocumentationText: {
    defaultMessage: 'For more information on billing, please {visit_documentation_link}.',
    id: '80OiUqxr',
    description: 'The text for the visit documentation message.',
  },
})
