import { BillingProfileStateStateEnum } from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  catchError,
  concat,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import { BillingProfileAPI } from '../apiMethods'
import {
  billingProfileAdded,
  confirmManualCreditRequestPayment,
  getManualCreditRequestAddCreditPageData,
  getManualCreditRequestChangePaymentMethodPageData,
  getManualCreditRequestChangePaymentMethodSetupStatus,
  getManualCreditRequestPaymentSummaryPageData,
  pollBillingProfileStateAfterPaymentConfirmation,
  stopPollBillingProfileStateAfterPaymentConfirmation,
  stopPollingForManualCreditChangePaymentMethod,
  updateCreditRequestAmount,
} from '../features/billingProfile/billingProfileSlice'
import { billingProfileCreditsBalanceAdded } from '../features/billingProfileCreditsBalance/billingProfileCreditsBalanceSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { stripeSetupIntentAdded } from '../features/stripeSetupIntent/stripeSetupIntentSlice'
import {
  getManualCreditRequestChangePaymentMethodGenericError,
  getManualCreditRequestChangePaymentMethodSucceeded,
} from '../notifications/clientToastNotificationContent/billingProfile'
import { getCreateOrganizationWizardGeneralErrorContent } from '../notifications/clientToastNotificationContent/createOrganizationWizard/getCreateOrganizationWizardGeneralErrorContent'
import {
  getManualCreditRequestInvoiceFailed,
  getManualCreditRequestSuccessful,
  getUnableToLoadBillingProfileError,
  getUpdateManualCreditRequestAmountError,
} from '../notifications/clientToastNotificationContent/manualCreditRequest'
import { getCreditAmountRequiredToViewPaymentSummaryError } from '../notifications/clientToastNotificationContent/manualCreditRequest/getCreditAmountRequriedToReviewPaymentSummaryError'
import { stripeConfirmCardPayment } from '../pages/CreateOrganizationWizardAddPaymentMethod/utils/stripe'
import {
  getManualCreditRequestAddCreditPage,
  getManualCreditRequestPaymentSummaryPage,
  getOrganizationBillingPagePath,
} from '../routes/routes-utils'
import { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onConfirmManualCreditRequestPayment: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(confirmManualCreditRequestPayment.match),
    switchMap(({ payload: { creditRequest, organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'confirmManualCreditRequestPayment', status: 'pending' })),
        from(BillingProfileAPI.getOrganizationPaymentSummaryConfirm({ organizationName })).pipe(
          takeUntil(action$.pipe(filter(stopPollBillingProfileStateAfterPaymentConfirmation.match))),
          mergeMap(() =>
            concat(
              of(
                pollBillingProfileStateAfterPaymentConfirmation({ creditRequest, organizationName }),
                setRequestStatus({ request: 'confirmManualCreditRequestPayment', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'confirmManualCreditRequestPayment', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'confirmManualCreditRequestPayment', status: 'failed' })),
              of(setRequestStatus({ request: 'confirmManualCreditRequestPayment', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getManualCreditRequestInvoiceFailed(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollBillingProfileStateAfterPaymentConfirmation: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(pollBillingProfileStateAfterPaymentConfirmation.match),
    switchMap(({ payload: { creditRequest, organizationName } }) => {
      let isStripeCardPaymentConfirmed = false
      return concat(
        of(setRequestStatus({ request: 'pollBillingProfileStateAfterPaymentConfirmation', status: 'pending' })),
        timer(0, 1000).pipe(
          switchMap(() =>
            from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
              distinctUntilChanged((prev, curr) => prev.state === curr.state),
              mergeMap((response) => {
                const { paymentMethodRequiredActionDetails, state } = response

                if (state === BillingProfileStateStateEnum.InvoiceSucceeded) {
                  return concat(
                    of(
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                      showToastNotification(getManualCreditRequestSuccessful(intl, creditRequest)),
                      navigateTo({ path: getOrganizationBillingPagePath(organizationName) }),
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterPaymentConfirmation',
                        status: 'succeeded',
                      }),
                    ),
                    of(
                      setRequestStatus({ request: 'pollBillingProfileStateAfterPaymentConfirmation', status: 'idle' }),
                    ).pipe(delay(1)),
                    of(stopPollBillingProfileStateAfterPaymentConfirmation()),
                  )
                }

                if (state === BillingProfileStateStateEnum.InvoiceFailed) {
                  return concat(
                    of(
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                      showToastNotification(getManualCreditRequestInvoiceFailed(intl)),
                    ),
                    of(
                      setRequestStatus({
                        request: 'pollBillingProfileStateAfterPaymentConfirmation',
                        status: 'failed',
                      }),
                    ),
                    of(
                      setRequestStatus({ request: 'pollBillingProfileStateAfterPaymentConfirmation', status: 'idle' }),
                    ).pipe(delay(1)),
                    of(stopPollBillingProfileStateAfterPaymentConfirmation()),
                  )
                }

                if (
                  state === BillingProfileStateStateEnum.PaymentMethodActionRequired &&
                  !isStripeCardPaymentConfirmed
                ) {
                  if (!paymentMethodRequiredActionDetails?.paymentIntentClientSecret) {
                    return concat(
                      of(
                        setRequestStatus({
                          request: 'pollBillingProfileStateAfterPaymentConfirmation',
                          status: 'failed',
                        }),
                      ),
                      of(showToastNotification(getManualCreditRequestInvoiceFailed(intl))),
                      of(stopPollBillingProfileStateAfterPaymentConfirmation()),
                      of(
                        setRequestStatus({
                          request: 'pollBillingProfileStateAfterPaymentConfirmation',
                          status: 'idle',
                        }),
                      ).pipe(delay(1)),
                    )
                  }

                  isStripeCardPaymentConfirmed = true

                  return from(
                    stripeConfirmCardPayment(intl, paymentMethodRequiredActionDetails.paymentIntentClientSecret),
                  ).pipe(
                    mergeMap(() => {
                      return EMPTY
                    }),
                    catchError(() => {
                      isStripeCardPaymentConfirmed = false
                      return concat(
                        of(
                          setRequestStatus({
                            request: 'pollBillingProfileStateAfterPaymentConfirmation',
                            status: 'failed',
                          }),
                        ),

                        of(showToastNotification(getManualCreditRequestInvoiceFailed(intl))),
                        of(stopPollBillingProfileStateAfterPaymentConfirmation()),
                        of(
                          setRequestStatus({
                            request: 'pollBillingProfileStateAfterPaymentConfirmation',
                            status: 'idle',
                          }),
                        ).pipe(delay(1)),
                      )
                    }),
                  )
                }

                return of(
                  billingProfileAdded({
                    organizationName,
                    billingProfile: response,
                  }),
                )
              }),
              catchError(() => of()), // TODO: Not sure what we want to do on error scenario here. Maybe show a toast?
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollBillingProfileStateAfterPaymentConfirmation.match))),
        ),
      )
    }),
  )

export const onGetManualCreditRequestAddCreditPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getManualCreditRequestAddCreditPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getManualCreditRequestAddCreditPageData', status: 'pending' })),
        from(
          Promise.all([
            BillingProfileAPI.getBillingProfile({ organizationName }),
            BillingProfileAPI.getBillingProfileCreditsBalance({ organizationName }),
          ]),
        ).pipe(
          mergeMap(([billingProfileResponse, billingProfileCreditsBalanceResponse]) => {
            return concat(
              of(
                billingProfileAdded({
                  organizationName,
                  billingProfile: billingProfileResponse,
                }),
                billingProfileCreditsBalanceAdded({
                  organizationName,
                  billingProfileCreditsBalance: billingProfileCreditsBalanceResponse,
                }),
                setRequestStatus({ request: 'getManualCreditRequestAddCreditPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getManualCreditRequestAddCreditPageData', status: 'idle' })).pipe(
                delay(1),
              ),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getManualCreditRequestAddCreditPageData', status: 'failed' })),
              of(setRequestStatus({ request: 'getManualCreditRequestAddCreditPageData', status: 'idle' })).pipe(
                delay(1),
              ),
              of(showToastNotification(getUnableToLoadBillingProfileError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetManualCreditRequestPaymentSummaryPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getManualCreditRequestPaymentSummaryPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'pending' })),
        from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
          mergeMap((response) => {
            if (response.addCreditsAmount == null) {
              return concat(
                of(
                  navigateTo({
                    path: getManualCreditRequestAddCreditPage(organizationName),
                  }),
                  setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'idle' })).pipe(
                  delay(1),
                ),
                of(showToastNotification(getCreditAmountRequiredToViewPaymentSummaryError(intl))),
              )
            }
            return concat(
              of(
                billingProfileAdded({
                  organizationName,
                  billingProfile: response,
                }),
                setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'idle' })).pipe(
                delay(1),
              ),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'failed' })),
              of(setRequestStatus({ request: 'getManualCreditRequestPaymentSummaryPageData', status: 'idle' })).pipe(
                delay(1),
              ),
              of(showToastNotification(getUnableToLoadBillingProfileError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onUpdateCreditRequestAmount: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(updateCreditRequestAmount.match),
    mergeMap(({ payload: { creditRequest, organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'updateCreditRequestAmount', status: 'pending' })),
        from(
          BillingProfileAPI.createCreditRequest({
            organizationName,
            createCreditRequest: {
              amount: creditRequest,
            },
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                navigateTo({
                  path: getManualCreditRequestPaymentSummaryPage(organizationName),
                }),
                setRequestStatus({ request: 'updateCreditRequestAmount', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'updateCreditRequestAmount', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'updateCreditRequestAmount', status: 'failed' })),
              of(setRequestStatus({ request: 'updateCreditRequestAmount', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getUpdateManualCreditRequestAmountError(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetManualCreditRequestChangePaymentMethodPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getManualCreditRequestChangePaymentMethodPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getManualCreditRequestChangePaymentMethodPageData', status: 'pending' })),
        from(
          Promise.all([
            BillingProfileAPI.getBillingProfile({ organizationName }),
            BillingProfileAPI.createOrganizationSetupIntents({ organizationName }),
          ]),
        ).pipe(
          mergeMap(([billingProfileResponse, setupIntentResponse]) =>
            concat(
              of(
                billingProfileAdded({
                  organizationName,
                  billingProfile: billingProfileResponse,
                }),
                stripeSetupIntentAdded({
                  setupIntentId: setupIntentResponse.setupIntentId,
                  clientSecret: setupIntentResponse.clientSecret,
                }),
                setRequestStatus({
                  request: 'getManualCreditRequestChangePaymentMethodPageData',
                  status: 'succeeded',
                }),
              ),
              of(
                setRequestStatus({
                  request: 'getManualCreditRequestChangePaymentMethodPageData',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({
                  request: 'getManualCreditRequestChangePaymentMethodPageData',
                  status: 'failed',
                }),
              ),
              of(
                setRequestStatus({
                  request: 'getManualCreditRequestChangePaymentMethodPageData',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
              // TODO: This fun stuff
              of(showToastNotification(getCreateOrganizationWizardGeneralErrorContent(intl))),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetManualCreditRequestChangePaymentMethodSetupStatus: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getManualCreditRequestChangePaymentMethodSetupStatus.match),
    switchMap(({ payload: { organizationName } }) => {
      let isStripeCardPaymentConfirmed = false

      return concat(
        of(setRequestStatus({ request: 'getManualCreditRequestChangePaymentMethodSetupStatus', status: 'pending' })),
        timer(0, 1000).pipe(
          switchMap(() =>
            from(BillingProfileAPI.getBillingProfile({ organizationName })).pipe(
              distinctUntilChanged((prev, curr) => prev.state === curr.state),
              mergeMap((response) => {
                const { paymentMethodRequiredActionDetails, state } = response

                if (state === BillingProfileStateStateEnum.SetupIntentSucceeded) {
                  return concat(
                    of(
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                    ),
                    of(showToastNotification(getManualCreditRequestChangePaymentMethodSucceeded(intl))),
                    of(
                      navigateTo({
                        path: getManualCreditRequestPaymentSummaryPage(organizationName),
                      }),
                    ),
                    of(
                      setRequestStatus({
                        request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                        status: 'succeeded',
                      }),
                    ),
                    of(
                      setRequestStatus({
                        request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                        status: 'idle',
                      }),
                    ).pipe(delay(1)),
                    of(stopPollingForManualCreditChangePaymentMethod()),
                  )
                }

                if (state === BillingProfileStateStateEnum.SetupIntentFailed) {
                  return concat(
                    of(
                      billingProfileAdded({
                        organizationName,
                        billingProfile: response,
                      }),
                    ),
                    of(showToastNotification(getManualCreditRequestChangePaymentMethodGenericError(intl))),
                    of(
                      setRequestStatus({
                        request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                        status: 'failed',
                      }),
                    ),
                    of(
                      setRequestStatus({
                        request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                        status: 'idle',
                      }),
                    ).pipe(delay(1)),
                    of(stopPollingForManualCreditChangePaymentMethod()),
                  )
                }

                if (
                  state === BillingProfileStateStateEnum.PaymentMethodActionRequired &&
                  !isStripeCardPaymentConfirmed
                ) {
                  if (!paymentMethodRequiredActionDetails?.paymentIntentClientSecret) {
                    return concat(
                      of(
                        setRequestStatus({
                          request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                          status: 'failed',
                        }),
                      ),
                      of(showToastNotification(getManualCreditRequestInvoiceFailed(intl))),
                      of(
                        setRequestStatus({
                          request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                          status: 'idle',
                        }),
                      ).pipe(delay(1)),
                    )
                  }

                  isStripeCardPaymentConfirmed = true

                  return from(
                    stripeConfirmCardPayment(intl, paymentMethodRequiredActionDetails.paymentIntentClientSecret),
                  ).pipe(
                    mergeMap(() => EMPTY),
                    catchError(() => {
                      isStripeCardPaymentConfirmed = false
                      return concat(
                        of(
                          setRequestStatus({
                            request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                            status: 'failed',
                          }),
                        ),
                        of(showToastNotification(getManualCreditRequestInvoiceFailed(intl))),
                        of(
                          setRequestStatus({
                            request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                            status: 'idle',
                          }),
                        ).pipe(delay(1)),
                      )
                    }),
                  )
                }

                return of(
                  billingProfileAdded({
                    organizationName,
                    billingProfile: response,
                  }),
                )
              }),
              catchError(() =>
                concat(
                  of(
                    setRequestStatus({
                      request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                      status: 'failed',
                    }),
                  ),
                  of(showToastNotification(getManualCreditRequestChangePaymentMethodGenericError(intl))),
                  of(
                    setRequestStatus({
                      request: 'getManualCreditRequestChangePaymentMethodSetupStatus',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                ),
              ),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingForManualCreditChangePaymentMethod.match))),
        ),
      )
    }),
  )
