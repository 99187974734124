import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { boolean, mixed, number, object, ref } from 'yup'
import { ConfigureCreditSettingsMessages } from './messages'
import { CreditSettingsFormValues } from './models'

export const useCreditSettingsFormValidation = (): ObjectSchema<CreditSettingsFormValues> => {
  const intl = useIntl()

  const validationSchema = useMemo(
    () =>
      object({
        initialCredit: mixed<string | number>().test({
          name: 'custom-validation',
          message: intl.formatMessage(ConfigureCreditSettingsMessages.creditAmountRequiredMessage),
          test(value, context) {
            const { createError } = context
            const automaticRecharge = this.parent.automaticRecharge
            if (value === '') {
              return false
            }
            const numberValue = Number(value)
            if (numberValue === 0 && !automaticRecharge) {
              return true
            } else {
              if (numberValue < 5) {
                return createError({
                  message: intl.formatMessage(ConfigureCreditSettingsMessages.customAmountMinMessage),
                })
              }
              if (numberValue > 1000) {
                return createError({
                  message: intl.formatMessage(ConfigureCreditSettingsMessages.customAmountMaxMessage),
                })
              }
              return true
            }
          },
        }),
        automaticRecharge: boolean(),
        automaticRechargeThreshold: number()
          .transform((value, originalValue) => (originalValue === '' ? null : value))
          .when(['automaticRecharge', 'automaticRechargeAmount'], {
            is: (automaticRecharge: boolean) => automaticRecharge === true,
            then: (schema) =>
              schema
                .required(intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
                .min(5, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
                .max(995, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
                .when('automaticRechargeAmount', {
                  is: (automaticRechargeAmount: number) => automaticRechargeAmount != null,
                  then: (subSchema) =>
                    subSchema.lessThan(
                      ref('automaticRechargeAmount'),
                      intl.formatMessage(
                        ConfigureCreditSettingsMessages.rechargeThresholdGreaterThanRechargeAmountErrorMessage,
                      ),
                    ),
                }),
            otherwise: (schema) => schema.notRequired(),
          }),
        automaticRechargeAmount: number()
          .transform((value, originalValue) => (originalValue === '' ? null : value))
          .when('automaticRecharge', {
            is: true,
            then: (schema) =>
              schema
                .required(intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText))
                .min(10, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText))
                .max(1000, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText)),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    [intl],
  )

  return validationSchema
}
