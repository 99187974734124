import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { PaymentSummaryMessages } from '../../messages'

export interface PaymentSummaryPaymentMethodProps {
  /** The card brand */
  creditCardBrand?: string
  /** The expiry date of the card */
  creditCardExpirationDate: string
  /** The last four digits of the card */
  creditCardLastFourDigits: string
  /** The callback executed when the user clicks the `Update` button */
  onUpdatePaymentSummary?: () => void
}

export const PaymentSummaryPaymentMethod: FunctionComponent<PaymentSummaryPaymentMethodProps> = ({
  creditCardBrand,
  creditCardExpirationDate,
  creditCardLastFourDigits,
  onUpdatePaymentSummary,
}) => {
  const intl = useIntl()

  const getCardIcon = (brand?: string) => {
    switch (brand) {
      case 'amex':
        return 'fa-brands fa-cc-amex'
      case 'discover':
        return 'fa-brands fa-cc-discover'
      case 'mastercard':
        return 'fa-brands fa-cc-mastercard'
      case 'visa':
        return 'fa-brands fa-cc-visa'
      default:
        return 'fa-solid fa-credit-card'
    }
  }

  return (
    <div className="flex items-center justify-between rounded-sm border border-neutral-50 px-4 py-2">
      <div className="flex items-center">
        <i className={`${getCardIcon(creditCardBrand)} text-5xl text-blue-90`} aria-hidden="true"></i>
        <div className="ml-4">
          <div className="text-sm font-bold">
            {intl.formatMessage(PaymentSummaryMessages.cardLast4Label, { last_four: creditCardLastFourDigits })}
          </div>
          <div className="text-sm text-neutral-80">
            {intl.formatMessage(PaymentSummaryMessages.creditCardExpirationDateLabel)} {creditCardExpirationDate}
          </div>
        </div>
      </div>

      {onUpdatePaymentSummary && (
        <Button onClick={onUpdatePaymentSummary} variant="green-filled-light">
          {intl.formatMessage(PaymentSummaryMessages.updateButtonLabel)}
        </Button>
      )}
    </div>
  )
}
