import { defineMessages } from 'react-intl'

export const AutoRefreshButtonMessages = defineMessages({
  pauseButtonLabel: {
    defaultMessage: 'Pause Auto-Refresh',
    id: '1KTdo31k',
    description: 'The label for the Pause Auto-Refresh button.',
  },
  resumeButtonLabel: {
    defaultMessage: 'Resume Auto-Refresh',
    id: 'aQsaCnzM',
    description: 'The label for the Resume Auto-Refresh button.',
  },
})
