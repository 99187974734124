import { SystemLogsItemsInner, SystemLogsQuery } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Link } from '../../../../../components/base'
import { Table } from '../../../../../components/block/Table'
import { TableColumnWidth, TableItem, TableSortingOrder } from '../../../../../components/block/Table/models'
import { Button } from '../../../../../components/Button'
import { FeaturePreviewPill } from '../../../../../components/featurePreview/FeaturePreviewPill'
import { DatePickerRange } from '../../../../../components/forms/DatePickerRange'
import { SearchInput } from '../../../../../components/search'
import { DeploymentInstance } from '../../../../../features/containerGroupInstanceTable/models'
import { useFixedForm } from '../../../../../hooks'
import { getInstancePath } from '../../../../../routes/routes-utils'
import { formatToDateTime } from '../../../../../utils'
import { SystemEventLogsField, SystemEventLogsFormValues } from '../../../models'
import { configureSystemLogDefaultValues, onDownloadSystemEventLogs } from '../../../utils'
import { DocumentIcon } from './components'
import { SystemEventLogsMessages } from './messages/SystemEventLogsMessages'

interface SystemEventLogsProps {
  /** The display name of the container group */
  containerGroupDisplayName: string
  /** The list of live container group instances */
  containerGroupInstances: DeploymentInstance[]
  /** The container group details page path */
  instanceDetailsPagePath: string
  /** The status of the system event logs request if pending */
  isGetSystemEventLogsPending: boolean
  /** The function to start polling the system event logs */
  onStartPollingSystemEventLogs: (systemEventLogFilters: SystemLogsQuery) => void
  /** The callback that stops polling the system event logs. */
  onStopPollingSystemEventLogs: () => void
  /** The system event logs array of items */
  systemEventLogs: SystemLogsItemsInner[]
}

export const SystemEventLogs: FunctionComponent<SystemEventLogsProps> = ({
  containerGroupDisplayName,
  containerGroupInstances,
  instanceDetailsPagePath,
  isGetSystemEventLogsPending,
  onStartPollingSystemEventLogs,
  onStopPollingSystemEventLogs,
  systemEventLogs,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [isSystemEventLogsDownloadLoading, setIsSystemEventLogsDownloadLoading] = useState(false)
  const systemLogDefaultValues = useMemo(() => configureSystemLogDefaultValues(), [])

  const { control, handleSubmit } = useFixedForm<SystemEventLogsFormValues>({
    defaultValues: systemLogDefaultValues,
    onSubmit: (data) => {
      querySystemEventLogs(data)
    },
  })

  const querySystemEventLogs = useCallback(
    (formData: SystemEventLogsFormValues) => {
      const { dateRange, event, gpuType, machineId, version } = formData
      const { endDate: endTime, startDate: startTime } = dateRange || { startDate: null, endDate: null }

      const query: SystemLogsQuery = {
        filterBy: event,
        machineId,
        gpuClassName: gpuType,
        containerVersion: version,
        startTime,
        endTime,
      }
      onStartPollingSystemEventLogs(query)
    },
    [onStartPollingSystemEventLogs],
  )

  const headers = [
    {
      key: SystemEventLogsMessages.eventLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.eventLabel),
      columnWidth: TableColumnWidth.FourTwelfths,
    },
    {
      key: SystemEventLogsMessages.machineIdLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.machineIdLabel),
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.createTimeLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.createTimeLabel),
      initialSort: true,
      direction: TableSortingOrder.Ascending,
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.gpuTypeLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.gpuTypeLabel),
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
    {
      key: SystemEventLogsMessages.versionLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.versionLabel),
      columnWidth: TableColumnWidth.OneTwelfth,
    },
    {
      key: SystemEventLogsMessages.viewInstanceDetailsLabel.id,
      label: intl.formatMessage(SystemEventLogsMessages.viewInstanceDetailsLabel),
      columnWidth: TableColumnWidth.OneTwelfth,
    },
  ]

  const liveContainerGroupMachineIds = containerGroupInstances.map((instance) => instance.machineId)

  const formattedSystemEventLogs: TableItem[] = useMemo(() => {
    return systemEventLogs?.map((log) => {
      const { createTime, gpuClassName, machineId, message, version } = log

      const instancePath = liveContainerGroupMachineIds.includes(machineId)
        ? getInstancePath(instanceDetailsPagePath, machineId)
        : null
      const onNavigateToInstanceDetailsPagePath = instancePath ? () => navigate(instancePath) : undefined

      return {
        [SystemEventLogsMessages.eventLabel.id]: { value: message, onClick: onNavigateToInstanceDetailsPagePath },
        [SystemEventLogsMessages.machineIdLabel.id]: { value: machineId, onClick: onNavigateToInstanceDetailsPagePath },
        [SystemEventLogsMessages.createTimeLabel.id]: {
          sortWith: new Date(createTime),
          value: formatToDateTime(intl, createTime),
          onClick: onNavigateToInstanceDetailsPagePath,
        },
        [SystemEventLogsMessages.gpuTypeLabel.id]: {
          value: gpuClassName,
          onClick: onNavigateToInstanceDetailsPagePath,
        },
        [SystemEventLogsMessages.versionLabel.id]: { value: version, onClick: onNavigateToInstanceDetailsPagePath },
        [SystemEventLogsMessages.viewInstanceDetailsLabel.id]: {
          value: instancePath ? <DocumentIcon /> : null,
          onClick: onNavigateToInstanceDetailsPagePath,
        },
      }
    })
  }, [systemEventLogs, liveContainerGroupMachineIds, instanceDetailsPagePath, intl, navigate])

  const isSystemEventLogsDownloadDisabled = systemEventLogs.length === 0

  const handleOnDownloadSystemEventLogs = () =>
    onDownloadSystemEventLogs(systemEventLogs, containerGroupDisplayName, setIsSystemEventLogsDownloadLoading, intl)

  useEffect(() => {
    querySystemEventLogs(systemLogDefaultValues)
  }, [querySystemEventLogs, systemLogDefaultValues])

  useEffect(() => {
    return () => {
      onStopPollingSystemEventLogs()
    }
  }, [onStopPollingSystemEventLogs])

  return (
    <>
      <div className="mt-4">
        <FeaturePreviewPill />
      </div>
      <div className="my-4 font-bold">{intl.formatMessage(SystemEventLogsMessages.recentContainerEventsHeader)}</div>
      <div className="my-4">
        {intl.formatMessage(SystemEventLogsMessages.systemEventLogsDescriptionText, {
          learn_more: (
            <Link url="https://docs.salad.com/products/sce/system-events">
              {intl.formatMessage(SystemEventLogsMessages.learnMoreLinkText)}
            </Link>
          ),
        })}
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 md:grid-cols-12">
        <div className="col-span-2 md:col-span-12">
          <Controller
            name={SystemEventLogsField.Event}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(SystemEventLogsMessages.filterEventPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="col-span-1 md:col-span-4">
          <Controller
            name={SystemEventLogsField.MachineId}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(SystemEventLogsMessages.filterByMachineIdPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="col-span-1 md:col-span-4">
          <Controller
            name={SystemEventLogsField.GpuType}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(SystemEventLogsMessages.filterByGpuClassPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="col-span-2 md:col-span-4">
          <Controller
            name={SystemEventLogsField.Version}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(SystemEventLogsMessages.versionLabel)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="col-span-2 md:col-span-6">
          <Controller
            name={SystemEventLogsField.DateRange}
            control={control}
            render={({ field }) => {
              const { onChange, value } = field
              const { endDate, startDate } = value || {
                startDate: systemLogDefaultValues.dateRange.startDate,
                endDate: systemLogDefaultValues.dateRange.endDate,
              }

              return (
                <DatePickerRange
                  id="log-date-range"
                  isFullWidth
                  hasTimeSelect
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  onChange={(newStartDate, newEndDate) => onChange({ startDate: newStartDate, endDate: newEndDate })}
                />
              )
            }}
          />
        </div>
        <div className="col-span-2 md:col-span-2">
          <Button
            type="button"
            iconClassName="fa-solid fa-download"
            isLoading={isSystemEventLogsDownloadLoading}
            isDisabled={isSystemEventLogsDownloadDisabled}
            onClick={handleOnDownloadSystemEventLogs}
            variant="green-filled-light"
            isFullWidth
          >
            {intl.formatMessage(SystemEventLogsMessages.downloadButtonLabel)}
          </Button>
        </div>
        <div className="col-span-2 md:col-span-4">
          <Button type="submit" isLoading={isGetSystemEventLogsPending} isFullWidth>
            {intl.formatMessage(SystemEventLogsMessages.queryButtonLabel)}
          </Button>
        </div>
      </form>
      <div className="py-2">
        <Table isLoading={isGetSystemEventLogsPending} items={formattedSystemEventLogs} headers={headers} />
      </div>
    </>
  )
}
