import type { UpdateContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelElementClickedEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectContainerGroup } from '../../features/containerGroups/containerGroupsSelectors'
import { editContainerGroup, getEditContainerGroupPageData } from '../../features/containerGroups/containerGroupsSlice'
import { configuresContainerGroupsEntityId } from '../../features/containerGroups/utils'
import { selectIsContainerGroupInstancesQuotaExceededModalShowing } from '../../features/createContainerGroup/createContainerGroupSelectors'
import { setRequiredActionModalShowingState } from '../../features/createContainerGroup/createContainerGroupSlice'
import type { RequiredActionModal } from '../../features/createContainerGroup/models'
import { selectGpuClasses } from '../../features/gpuClasses/gpuClassesSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectContainerGroupInstanceQuota } from '../../features/quotas/quotasSelectors'
import { selectRamOptions } from '../../features/ramOptions/ramOptionsSelectors'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectStorageOptions } from '../../features/storageOptions/storageOptionsSelectors'
import { editContainerGroupPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupDetailsPagePath, getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { getRequestContainerGroupInstanceQuotaIncreaseLink } from '../../utils'
import { EditContainerGroupPage } from './EditContainerGroupPage'

export const ConnectedEditContainerGroupPage: FunctionComponent = () => {
  const { containerGroupName = '', organizationName = '', projectName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetEditContainerGroupPageDataPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getEditContainerGroupPageData'),
  )
  const maxReplicaCount = useAppSelector((state) => selectContainerGroupInstanceQuota(state, organizationName))
  const isContainerGroupInstancesQuotaExceededModalShowing = useAppSelector(
    selectIsContainerGroupInstancesQuotaExceededModalShowing,
  )
  const containerGroup = useAppSelector((state) =>
    selectContainerGroup(state, configuresContainerGroupsEntityId(organizationName, projectName), containerGroupName),
  )
  const gpuOptions = useAppSelector((state) => selectGpuClasses(state.gpuClasses, organizationName))
  const isEditContainerGroupRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'editContainerGroup'),
  )
  const ramOptions = useAppSelector((state) => selectRamOptions(state.ramOptions))
  const storageOptions = useAppSelector((state) => selectStorageOptions(state.storageOptions))

  const email = useAppSelector(selectProfileEmail)

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const linkToRequestIncreaseForContainerGroupInstancesQuotas = getRequestContainerGroupInstanceQuotaIncreaseLink(
    email as string,
    organizationName,
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleSetModalShowingState = useCallback(
    (modal: RequiredActionModal, showing: boolean) => {
      dispatch(setRequiredActionModalShowingState({ requiredAction: modal, showing: showing }))
    },
    [dispatch],
  )

  const onEditContainerGroup = useCallback(
    (values: UpdateContainerGroup) => {
      dispatch(editContainerGroup({ containerGroupName, organizationName, projectName, updateContainerGroup: values }))
    },
    [containerGroupName, dispatch, organizationName, projectName],
  )

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: editContainerGroupPageRoutePath,
          organizationName,
          projectName,
          resourceName: containerGroupName,
        }),
      )
    },
    [dispatch, organizationName, projectName, containerGroupName],
  )

  useEffect(() => {
    dispatch(getEditContainerGroupPageData({ organizationName, projectName, containerGroupName }))
    dispatch(
      trackMixpanelPageViewedEvent({
        path: editContainerGroupPageRoutePath,
        organizationName,
        projectName,
        resourceName: containerGroupName,
      }),
    )
  }, [dispatch, organizationName, projectName, containerGroupName])

  return isGetEditContainerGroupPageDataPending || containerGroup === undefined ? (
    <LoadingPage />
  ) : (
    <EditContainerGroupPage
      containerGroupDetailsPath={getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName)}
      currentContainerGroup={containerGroup}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      gpuOptions={gpuOptions}
      isContainerGroupInstancesQuotaExceededModalShowing={isContainerGroupInstancesQuotaExceededModalShowing}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isEditContainerGroupDetailsPending={isEditContainerGroupRequestPending}
      isLeftColumnOpen={isLeftColumnOpen}
      linkToRequestIncreaseForContainerGroupInstancesQuotas={linkToRequestIncreaseForContainerGroupInstancesQuotas}
      maxReplicaCount={maxReplicaCount}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      onSetContainerGroupInstancesQuotaExceededModalShowingState={(showing) =>
        handleSetModalShowingState('instancesQuota', showing)
      }
      onSubmit={onEditContainerGroup}
      projects={projectSelectOptions}
      ramOptions={ramOptions}
      storageOptions={storageOptions}
    />
  )
}
