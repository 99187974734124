import { IntlShape } from 'react-intl'
import { createToastNotification } from '../../../../../notifications'
import {
  getInstanceLogsDownloadErrorContent,
  getInstanceLogsDownloadSuccessfullyStartedContent,
} from '../../../../../notifications/clientToastNotificationContent/containerGroupInstanceLogs'
import { TimeOffsets, TimeRange } from '../../../../forms/DatePickerRange/models'
import { InstanceLog, InstanceLogsFormValues, InstanceLogsQuery } from './models'

/**
 * A function that configures the default values for the instance log form. Defaults to one hour
 *
 * @param newDefaults The new default values to be applied.
 * @returns The configured instance log form values.
 */
export const configureInstanceLogsDefaultValues = (
  newDefaults: Partial<InstanceLogsFormValues> = {},
): InstanceLogsFormValues => {
  return {
    filterBy: null,
    dateRange: {
      startDate: newDefaults.dateRange?.startDate ?? new Date(Date.now() - TimeOffsets[TimeRange.SevenDays]),
      endDate: newDefaults.dateRange?.endDate ?? new Date(),
    },
    ...newDefaults,
  }
}

/**
 * The function used to download Instance Logs as a CSV file.
 *
 * @param instanceLogs The data that is downloaded
 * @param containerGroupDisplayName The container group name that is used in the download file
 * @param instanceId The instance ID that is used in the download file
 * @param onToggleLoading Toggles the loading state of the download button
 * @param intl The Intl Shape
 */
export const onDownloadInstanceLogs = (
  instanceLogs: InstanceLog[],
  containerGroupDisplayName: string,
  instanceId: string,
  onToggleLoading: (isLoading: boolean) => void,
  intl: IntlShape,
) => {
  if (instanceLogs.length === 0) return

  const csvHeaders = ['Message/Event', 'Time (UTC)']
  const csvRows = instanceLogs.map((instanceLog) => {
    const { eventMessage, time } = instanceLog
    return [eventMessage, new Date(time).toISOString()]
  })
  const headers = csvHeaders.join(',')
  const csvContent = [headers, ...csvRows.map((row) => row.join(','))].join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute(
    'download',
    `${containerGroupDisplayName}-InstanceId:${instanceId}-instance-logs-${intl.formatDate(Date.now(), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })}.csv`,
  )
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  try {
    onToggleLoading(true)
    link.click()
    createToastNotification(getInstanceLogsDownloadSuccessfullyStartedContent(intl))
  } catch (error) {
    createToastNotification(getInstanceLogsDownloadErrorContent(intl))
  } finally {
    onToggleLoading(false)
  }
  document.body.removeChild(link)
}

/**
 * A function that converts the form data to the instance logs query that we need to query/poll for instance logs
 *
 * @param formData The form data used to query the instance logs
 * @param instanceId The instance ID for the instance logs
 * @returns
 */
export const convertFormDataToInstanceLogsQuery = (
  formData: InstanceLogsFormValues,
  instanceId: string,
): InstanceLogsQuery => {
  const { dateRange, filterBy } = formData
  const { endDate: endTime, startDate: startTime } = dateRange || { startDate: null, endDate: null }
  return {
    instanceId,
    filterBy,
    startTime,
    endTime,
  }
}
