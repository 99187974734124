/**
 * Configures the container groups instance entity ID based on the organization name, project name and container group
 * name.
 *
 * @param organizationName The organization name
 * @param projectName The project name
 * @param containerGroupName The container group name
 * @param instanceId The instance ID
 */
export const configureContainerGroupInstanceLogsEntityId = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
  instanceId: string,
) => `${organizationName}:${projectName}:${containerGroupName}:${instanceId}`
