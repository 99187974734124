import { defineMessages } from 'react-intl'

export const SystemEventLogsMessages = defineMessages({
  containerVersionLabel: {
    defaultMessage: 'Container Version',
    id: 'WaPTIeN3',
    description: 'Label for the Container Version column in system event logs table',
  },
  createTimeLabel: {
    defaultMessage: 'Time (UTC)',
    id: 'fSk5IMlj',
    description: 'Label for the Time column in system event logs table',
  },
  downloadButtonLabel: {
    defaultMessage: 'Download',
    id: 'zCo8JC3p',
    description: 'The download button label',
  },
  eventLabel: {
    defaultMessage: 'Event',
    id: 'DEIQPC5r',
    description: 'Label for the Event column in system event logs table',
  },
  filterByContainerVersionPlaceholder: {
    defaultMessage: 'Container Version',
    id: 'bMAdsEoM',
    description: 'Filter by Container Version placeholder text',
  },
  filterByGpuClassPlaceholder: {
    defaultMessage: 'GPU',
    id: 's9qJi7i8',
    description: 'Filter by GPU Class placeholder text',
  },
  filterByInstanceIdPlaceholder: {
    defaultMessage: 'Instance ID',
    id: 'zwCjea2U',
    description: 'Filter by Instance ID placeholder text',
  },
  filterByMachineIdPlaceholder: {
    defaultMessage: 'Machine ID',
    id: 'adFk44D6',
    description: 'Filter by Machine ID placeholder text',
  },
  filterBySourcePlaceholder: {
    defaultMessage: 'Source',
    id: 'A/0bUQYi',
    description: 'Filter by source placeholder text',
  },
  filterEventPlaceholder: {
    defaultMessage: 'Event',
    id: 'RJh6/CJC',
    description: 'Placeholder for full-text event search filter input',
  },
  gpuTypeLabel: {
    defaultMessage: 'GPU',
    id: 'Ol7YyNwF',
    description: 'Label for the GPU Type column in system event logs table',
  },
  instanceIdLabel: {
    defaultMessage: 'Instance ID',
    id: 'rwUTNckk',
    description: 'Label for the Instance ID column in system event logs table',
  },
  learnMoreLinkText: {
    defaultMessage: 'Learn More.',
    id: 'xw75uIMl',
    description: 'Link text for learn more',
  },
  machineIdLabel: {
    defaultMessage: 'Machine ID',
    id: 'c7oqE8KU',
    description: 'Label for the Machine ID column in system event logs table',
  },
  queryButtonLabel: {
    defaultMessage: 'Query',
    id: 'xcdYB8us',
    description: 'Run the query',
  },
  recentContainerEventsHeader: {
    defaultMessage: 'Recent Container Events',
    id: 'yuFt2tS1',
    description: 'Header for recent container events section',
  },
  sourceLabel: {
    defaultMessage: 'Source',
    id: 'BtyuP8Cu',
    description: 'Label for the Source column in system event logs table',
  },
  systemEventLogsDescriptionText: {
    defaultMessage:
      'Up to 1,000 events can be retrieved. Access the logs for a specific instance by clicking any row with a Machine ID. Explore more about these events in our documentation: {learn_more}',
    id: 'NqKrhqbq',
    description: 'System event logs description',
  },
  versionLabel: {
    defaultMessage: 'Version',
    id: 'r4D0vPDD',
    description: 'Label for the Version column in system event logs table',
  },
  viewInstanceDetailsLabel: {
    defaultMessage: ' ',
    id: '/QQBFrSv',
    description: 'Label for the View Instance Details button',
  },
})
