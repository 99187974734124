import type { Organization } from '@saladtechnologies/openapi-cloud-portal-browser'
import { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  catchError,
  concat,
  concatMap,
  delay,
  EMPTY,
  filter,
  from,
  map,
  mergeMap,
  of,
  reduce,
  withLatestFrom,
} from 'rxjs'
import { CreateOrganizationWizardAPI, InvitationsAPI, MembersAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import { createOrganizationWizardsReceived } from '../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectLastViewedResourceByOrganization } from '../features/lastResourceViewed/lastResourceViewedSelectors'
import type {
  OrganizationWithMembersAndInvitationsResponse,
  SwitchToOrganizationPath,
} from '../features/myOrganizations/models'
import {
  getOrganizationsAndOrganizationInvitations,
  getOrganizationsPageData,
  setInvitationList,
  setMyOrganizationsList,
} from '../features/myOrganizations/myOrganizationSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationsReceived } from '../features/organizations/organizationsSlice'
import type { ProjectsProps } from '../features/projects/projectsSlice'
import { projectsReceived } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getContainerGroupsPagePath, getNoProjectsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { convertKeysToCamelCase } from '../utils'

export const onGetOrganizationsAndOrganizationInvitations: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(getOrganizationsAndOrganizationInvitations.match),
    mergeMap(() =>
      concat(
        of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'pending' })),
        from(InvitationsAPI.listUserInvitations({})).pipe(
          mergeMap((response) => {
            return of(setInvitationList({ invitations: response }))
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
        from(OrganizationsAPI.listOrganizations({})).pipe(
          mergeMap((response) => {
            return from(response.items)
          }),
          concatMap((organization) => {
            return from(
              Promise.all([
                organization,
                InvitationsAPI.listInvitations({
                  organizationName: organization.name,
                }),
                MembersAPI.listMembers({
                  organizationName: organization.name,
                }),
                ProjectsAPI.listProjects({
                  organizationName: organization.name,
                }),
              ]),
            ).pipe(
              catchError((error: unknown) => {
                if (error instanceof ResponseError && error.response.status === 404) {
                  return EMPTY
                }

                throw error
              }),
            )
          }),
          reduce(
            (responses, [organization, invitationsList, membersList, projectsList]) => [
              ...responses,
              {
                organization,
                invitations: invitationsList.items,
                memberCount: membersList.items.length,
                projects: projectsList.items,
                projectCount: projectsList.items.length,
              },
            ],
            [] as OrganizationWithMembersAndInvitationsResponse[],
          ),
          withLatestFrom(state$),
          mergeMap(([myOrganizationList, state]) => {
            const organizations: Organization[] = []
            const projects: ProjectsProps[] = []
            let switchToOrgPath: SwitchToOrganizationPath = {}
            myOrganizationList.forEach((data) => {
              organizations.push(data.organization)
              projects.push({ organizationName: data.organization.name, projects: data.projects })
              const organizationName = data.organization.name
              const organizationProjects = data.projects
              const lastResourceViewed = selectLastViewedResourceByOrganization(state, organizationName)

              if (organizationProjects.length === 0) {
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: getNoProjectsPagePath(organizationName),
                }
              } else if (lastResourceViewed && lastResourceViewed.projectName) {
                const path = getContainerGroupsPagePath(organizationName, lastResourceViewed.projectName)
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: path,
                }
              } else {
                const lastCreatedProject = organizationProjects.reduce((prev, current) =>
                  prev.createTime > current.createTime ? prev : current,
                )
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: getContainerGroupsPagePath(organizationName, lastCreatedProject.name),
                }
              }
            })
            return concat(
              of(
                setMyOrganizationsList({
                  myOrganizationsResponse: myOrganizationList,
                  currentUserEmail: state.profile.profile?.email,
                  switchToOrgPath,
                }),
                organizationsReceived({ organizations }),
                projectsReceived({ projects }),
                setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            const genericGetOrganizationListErrorMessage =
              'We were unable to get your list of organizations. Please try refreshing the page.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json())
                  .pipe(map((errorResponse) => errorResponse))
                  .pipe(
                    mergeMap((_errorMessage) => {
                      return concat(
                        of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'failed' })),
                        of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(
                          showToastNotification({
                            body: genericGetOrganizationListErrorMessage,
                            title: 'Unable to retrieve organizations',
                            type: 'error',
                          }),
                        ),
                      )
                    }),
                  )
              : concat(
                  of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'failed' })),
                  of(setRequestStatus({ request: 'getMyOrganizationsListAndInvitations', status: 'idle' })).pipe(
                    delay(1),
                  ),
                  of(
                    showToastNotification({
                      body: genericGetOrganizationListErrorMessage,
                      title: 'Unable to retrieve organizations',
                      type: 'error',
                    }),
                  ),
                )
          }),
        ),
      ),
    ),
  )

export const onGetOrganizationsPageData: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(getOrganizationsPageData.match),
    mergeMap(() =>
      concat(
        of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'pending' })),
        from(
          Promise.all([
            InvitationsAPI.listUserInvitations({}),
            CreateOrganizationWizardAPI.listCreateOrganizationWizards(),
          ]),
        ).pipe(
          mergeMap(([invitationListResponse, createOrganizationWizardListResponse]) => {
            const formattedCreateOrganizationWizards = convertKeysToCamelCase(
              createOrganizationWizardListResponse.items,
            )

            return of(
              setInvitationList({ invitations: invitationListResponse }),
              createOrganizationWizardsReceived({
                createOrganizationWizards: formattedCreateOrganizationWizards,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
        from(OrganizationsAPI.listOrganizations({})).pipe(
          mergeMap((response) => {
            return from(response.items)
          }),
          concatMap((organization) => {
            return from(
              Promise.all([
                organization,
                InvitationsAPI.listInvitations({
                  organizationName: organization.name,
                }),
                MembersAPI.listMembers({
                  organizationName: organization.name,
                }),
                ProjectsAPI.listProjects({
                  organizationName: organization.name,
                }),
              ]),
            ).pipe(
              catchError((error: unknown) => {
                if (error instanceof ResponseError && error.response.status === 404) {
                  return EMPTY
                }

                throw error
              }),
            )
          }),
          reduce(
            (responses, [organization, invitationsList, membersList, projectsList]) => [
              ...responses,
              {
                organization,
                invitations: invitationsList.items,
                memberCount: membersList.items.length,
                projects: projectsList.items,
                projectCount: projectsList.items.length,
              },
            ],
            [] as OrganizationWithMembersAndInvitationsResponse[],
          ),
          withLatestFrom(state$),
          mergeMap(([myOrganizationList, state]) => {
            const organizations: Organization[] = []
            const projects: ProjectsProps[] = []
            let switchToOrgPath: SwitchToOrganizationPath = {}
            myOrganizationList.forEach((data) => {
              organizations.push(data.organization)
              projects.push({ organizationName: data.organization.name, projects: data.projects })
              const organizationName = data.organization.name
              const organizationProjects = data.projects
              const lastResourceViewed = selectLastViewedResourceByOrganization(state, organizationName)

              if (organizationProjects.length === 0) {
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: getNoProjectsPagePath(organizationName),
                }
              } else if (lastResourceViewed && lastResourceViewed.projectName) {
                const path = getContainerGroupsPagePath(organizationName, lastResourceViewed.projectName)
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: path,
                }
              } else {
                const lastCreatedProject = organizationProjects.reduce((prev, current) =>
                  prev.createTime > current.createTime ? prev : current,
                )
                switchToOrgPath = {
                  ...switchToOrgPath,
                  [organizationName]: getContainerGroupsPagePath(organizationName, lastCreatedProject.name),
                }
              }
            })
            return concat(
              of(
                setMyOrganizationsList({
                  myOrganizationsResponse: myOrganizationList,
                  currentUserEmail: state.profile.profile?.email,
                  switchToOrgPath,
                }),
                organizationsReceived({ organizations }),
                projectsReceived({ projects }),
                setRequestStatus({ request: 'getOrganizationsPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            const genericGetOrganizationListErrorMessage =
              'We were unable to get your list of organizations. Please try refreshing the page.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json())
                  .pipe(map((errorResponse) => errorResponse))
                  .pipe(
                    mergeMap((_errorMessage) => {
                      return concat(
                        of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'failed' })),
                        of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'idle' })).pipe(delay(1)),
                        of(
                          showToastNotification({
                            body: genericGetOrganizationListErrorMessage,
                            title: 'Unable to retrieve organizations',
                            type: 'error',
                          }),
                        ),
                      )
                    }),
                  )
              : concat(
                  of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'failed' })),
                  of(setRequestStatus({ request: 'getOrganizationsPageData', status: 'idle' })).pipe(delay(1)),
                  of(
                    showToastNotification({
                      body: genericGetOrganizationListErrorMessage,
                      title: 'Unable to retrieve organizations',
                      type: 'error',
                    }),
                  ),
                )
          }),
        ),
      ),
    ),
  )
