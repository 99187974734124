import { createSelector } from '@reduxjs/toolkit'
import { containerGroupInstanceLogsAdapter } from './containerGroupInstanceLogsSlice'

const containerGroupInstanceLogsSelectors = containerGroupInstanceLogsAdapter.getSelectors()

export const selectContainerGroupInstanceLogs = createSelector(
  containerGroupInstanceLogsSelectors.selectById,
  (containerGroup) => {
    return containerGroup?.containerGroupInstanceLogs ?? []
  },
)
