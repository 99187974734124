import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizard } from '../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  confirmPaymentSummary,
  getCreateOrganizationWizard,
  stopPollingForCreateOrganizationWizardSubmission,
} from '../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../routes/routePaths'
import { getCreateOrganizationWizardAddPaymentMethodPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { PaymentSummaryBackWarningModal } from './components/PaymentSummaryBackWarningModal'
import { CreateOrganizationWizardPaymentSummaryPage } from './CreateOrganizationWizardPaymentSummaryPage'

export const ConnectedCreateOrganizationWizardPaymentSummaryPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isPaymentSummaryBackWarningModalOpen, setIsPaymentSummaryBackWarningModalOpen] = useState(false)

  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )

  const isGetOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getCreateOrganizationWizard') === 'pending',
  )

  const isConfirmPaymentSummaryPending = useAppSelector(
    (state) => selectRequestStatus(state, 'confirmPaymentSummary') === 'pending',
  )

  const creditCardExpirationDate = `${createOrganizationWizard?.paymentMethodDetails?.expirationMonth}/${createOrganizationWizard?.paymentMethodDetails?.expirationYear}`
  const creditCardLastFourDigits = createOrganizationWizard?.paymentMethodDetails?.lastFour
  const creditCardBrand = createOrganizationWizard?.paymentMethodDetails?.brand
  const estimatedTax = createOrganizationWizard?.estimatedTax
  const initialCredit = createOrganizationWizard?.initialCredit ?? 0
  const organizationReservedName = createOrganizationWizard?.organizationReservedName

  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isEstimatedTaxLoading = estimatedTax === undefined || estimatedTax === null
  const isPaymentDetailsLoading =
    createOrganizationWizard?.paymentMethodDetails?.expirationMonth === undefined ||
    createOrganizationWizard?.paymentMethodDetails?.expirationYear === undefined ||
    creditCardLastFourDigits === undefined

  const handleOpenPaymentSummaryBackWarningModal = useCallback(() => {
    setIsPaymentSummaryBackWarningModalOpen(true)
  }, [])

  const handlePaymentSummaryBackWarningModalCancel = useCallback(() => {
    setIsPaymentSummaryBackWarningModalOpen(false)
  }, [])

  const handlePaymentSummaryBackWarningModalConfirm = useCallback(() => {
    setIsPaymentSummaryBackWarningModalOpen(false)
    navigate(getCreateOrganizationWizardAddPaymentMethodPagePath(createOrganizationWizardId))
  }, [createOrganizationWizardId, navigate])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleConfirmPaymentSummary = useCallback(() => {
    if (organizationReservedName) {
      dispatch(confirmPaymentSummary({ organizationReservedName, createOrganizationWizardId }))
    }
  }, [dispatch, organizationReservedName, createOrganizationWizardId])

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.PaymentMethodSummary },
      }),
    )

    return () => {
      dispatch(stopPollingForCreateOrganizationWizardSubmission({ createOrganizationWizardId }))
    }
  }, [dispatch, createOrganizationWizardId])

  return (
    <>
      {isGetOrganizationWizardPending || isEstimatedTaxLoading || isPaymentDetailsLoading ? (
        <LoadingPage />
      ) : (
        <CreateOrganizationWizardPaymentSummaryPage
          creditCardBrand={creditCardBrand}
          creditCardExpirationDate={creditCardExpirationDate}
          creditCardLastFourDigits={creditCardLastFourDigits}
          estimatedTax={estimatedTax}
          initialCredit={initialCredit}
          isConfirmPaymentSummaryPending={isConfirmPaymentSummaryPending}
          isLeftColumnOpen={isLeftColumnOpen}
          onBack={handleOpenPaymentSummaryBackWarningModal}
          onCloseLeftDrawer={handleCloseLeftNavBar}
          onConfirmPaymentSummary={handleConfirmPaymentSummary}
        />
      )}

      {isPaymentSummaryBackWarningModalOpen && (
        <PaymentSummaryBackWarningModal
          onCancel={handlePaymentSummaryBackWarningModalCancel}
          onLeavePage={handlePaymentSummaryBackWarningModalConfirm}
        />
      )}
    </>
  )
}
