import { FunctionComponent } from 'react'
import { CreateOrganizationWizardProgressBar } from '../../components/CreateOrganizationWizard/CreateOrganizationWizardProgressBar'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateOrganizationWizardOrganizationInformationMainContent } from './components/CreateOrganizationWizardOrganizationInformationMainContent'

export interface CreateOrganizationWizardOrganizationInformationPageProps {
  /** The ID of the create organization wizard. */
  createOrganizationWizardId: string
  /** A flag indicating that the request to check if the organization name is available is pending. */
  isCheckOrganizationNameAvailabilityPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A flag indicating if the organization name is available. */
  isOrganizationNameAvailable?: boolean
  /** The flag indicating that the organization name is pending. */
  isReserveOrganizationNamePending: boolean
  /** A flag indicating if the component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** A callback that when executed checks if the organization name is available. */
  onCheckOrganizationNameAvailability: (organizationName: string, turnstileSuccessToken: string) => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed reserves the organization name */
  onReserveOrganizationName: (organizationName: string) => void
  /** A callback that when executed resets the organization name availability flag. */
  onResetOrganizationNameAvailabilityFlag: () => void
  /** A callback that when executed tracks a Mixpanel event. */
  onTrackTurnstileMixpanelEvent: (eventName: string, turnstileErrorCode?: string) => void
  /** The default value for the organization reserved name. */
  organizationReservedNameDefaultValue?: string
}

export const CreateOrganizationWizardOrganizationInformationPage: FunctionComponent<
  CreateOrganizationWizardOrganizationInformationPageProps
> = ({
  createOrganizationWizardId,
  isCheckOrganizationNameAvailabilityPending,
  isLeftColumnOpen,
  isOrganizationNameAvailable,
  isReserveOrganizationNamePending,
  isStorybookRender,
  onCheckOrganizationNameAvailability,
  onCloseLeftDrawer,
  onReserveOrganizationName,
  onResetOrganizationNameAvailabilityFlag,
  onTrackTurnstileMixpanelEvent,
  organizationReservedNameDefaultValue,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar currentPage={CreateOrganizationWizardPageSteps.OrganizationInformation} />
      }
      MainColumn={
        <CreateOrganizationWizardOrganizationInformationMainContent
          createOrganizationWizardId={createOrganizationWizardId}
          isCheckOrganizationNameAvailabilityPending={isCheckOrganizationNameAvailabilityPending}
          isOrganizationNameAvailable={isOrganizationNameAvailable}
          isReserveOrganizationNamePending={isReserveOrganizationNamePending}
          isStorybookRender={isStorybookRender}
          onCheckOrganizationNameAvailability={onCheckOrganizationNameAvailability}
          onReserveOrganizationName={onReserveOrganizationName}
          onResetOrganizationNameAvailabilityFlag={onResetOrganizationNameAvailabilityFlag}
          onTrackTurnstileMixpanelEvent={onTrackTurnstileMixpanelEvent}
          organizationReservedNameDefaultValue={organizationReservedNameDefaultValue}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
