import { SystemLogsItemsInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { IntlShape } from 'react-intl'
import { Link } from '../../components/base'
import { TimeOffsets, TimeRange } from '../../components/forms/DatePickerRange/models'
import { createToastNotification } from '../../notifications'
import {
  getSystemEventLogsDownloadErrorContent,
  getSystemEventLogsDownloadSuccessfullyStartedContent,
} from '../../notifications/clientToastNotificationContent/systemEventLogs'
import { ContainerGroupDetailsMainContentMessages } from './components/ContainerGroupDetailsMainContent/ContainerGroupDetailsMainContentMessages'
import { ContainerGroupLogsFormValues, SystemEventLogsFormValues } from './models'

/**
 * A function that generates the `Failed to Pull Image` message which details why the container group failed along with
 * a link for more documentation.
 *
 * @param intl The intl object used to format and configure messages.
 */
export const getFailedToPullImageMessage = (intl: IntlShape) => {
  return intl.formatMessage(ContainerGroupDetailsMainContentMessages.failedToPullImageDescription, {
    learn_more_here_link: (
      <Link url="https://docs.salad.com/products/sce/container-registries#specifying-the-container-image-source">
        {intl.formatMessage(ContainerGroupDetailsMainContentMessages.learnMoreHereLinkText)}
      </Link>
    ),
  })
}

/**
 * A function that determines if the container group failed to pull the image based on the description provided.
 *
 * @param description The container group description pulled from the `currentState` object.
 */
export const detectFailedToPullImageDescription = (description: string | undefined | null) => {
  return description?.toUpperCase() === 'FAILED TO PULL IMAGE'
}

/**
 * A function that configures the default values for the container log form. Defaults to one hour
 *
 * @param newDefaults The new default values to be applied.
 * @returns The configured container log form values.
 */
export const configureContainerGroupLogDefaultValues = (
  newDefaults: Partial<ContainerGroupLogsFormValues> = {},
): ContainerGroupLogsFormValues => {
  return {
    filterBy: '',
    machineId: '',
    dateRange: {
      startDate: new Date(Date.now() - TimeOffsets[TimeRange.OneHour]),
      endDate: new Date(),
      ...newDefaults.dateRange,
    },
    ...newDefaults,
  }
}

/**
 * A function that configures the default values for the system logs form. Defaults to seven days.
 *
 * @param newDefaults The new default values to be applied.
 * @returns The configured system logs form values.
 */
export const configureSystemLogDefaultValues = (
  newDefaults: Partial<SystemEventLogsFormValues> = {},
): SystemEventLogsFormValues => {
  return {
    event: null,
    machineId: null,
    gpuType: null,
    version: null,
    dateRange: {
      startDate: newDefaults.dateRange?.startDate ?? new Date(Date.now() - TimeOffsets[TimeRange.SevenDays]),
      endDate: newDefaults.dateRange?.endDate ?? new Date(),
    },
    ...newDefaults,
  }
}

/**
 * Determines the time range based on the start and end date provided.
 *
 * @param start The start date of the time range.
 * @param end The end date of the time range.
 * @returns The time range based on the start and end date.
 */
export const determineTimeRange = (start: Date | null, end: Date | null): TimeRange => {
  if (!start || !end) return TimeRange.Custom

  const now = new Date()
  const startNormalized = new Date(start).getTime()
  const nowNormalized = new Date(now).getTime()

  const diff = nowNormalized - startNormalized

  for (const range of Object.keys(TimeOffsets).map(Number) as Array<Exclude<TimeRange, TimeRange.Custom>>) {
    if (TimeOffsets[range] && Math.abs(diff - TimeOffsets[range]) < 1000) {
      return range
    }
  }

  return TimeRange.Custom
}

/**
 * The function used to download System Event Logs as a CSV file.
 *
 * @param systemEventLogs The data that is downloaded
 * @param containerGroupName The container group name that is used in the download file
 * @param onToggleLoading Toggles the loading state of the download button
 * @param intl The Intl Shape
 */
export const onDownloadSystemEventLogs = (
  systemEventLogs: SystemLogsItemsInner[],
  containerGroupName: string,
  onToggleLoading: (isLoading: boolean) => void,
  intl: IntlShape,
) => {
  if (systemEventLogs.length === 0) return

  const csvHeaders = ['Message', 'Instance ID', 'Machine ID', 'Create Time', 'Version']
  const csvRows = systemEventLogs.map(({ createTime, instanceId, machineId, message, version }) => [
    message,
    instanceId,
    machineId,
    new Date(createTime).toISOString(),
    version || '',
  ])

  const csvContent = [csvHeaders.join(','), ...csvRows.map((row) => row.join(','))].join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute(
    'download',
    `${containerGroupName}-system-event-logs-${intl.formatDate(Date.now(), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })}.csv`,
  )

  document.body.appendChild(link)
  try {
    onToggleLoading(true)
    link.click()
    createToastNotification(getSystemEventLogsDownloadSuccessfullyStartedContent(intl))
  } catch (error) {
    createToastNotification(getSystemEventLogsDownloadErrorContent(intl))
  } finally {
    onToggleLoading(false)
    document.body.removeChild(link)
  }
}
