import {
  QueryContainerGroupLogsProblemType,
  ResponseError,
  SystemLogsProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, switchMap, takeUntil, timer } from 'rxjs'
import { ContainerGroupsAPI, SystemLogsAPI } from '../apiMethods'
import { InstanceLog } from '../components/instances/InstanceDetailsMainContent/components/InstanceLogs/models'
import {
  containerGroupInstanceLogsAdded,
  getContainerGroupInstanceLogs,
  startPollingContainerGroupInstanceLogs,
  stopPollingContainerGroupInstanceLogs,
} from '../features/containerGroupInstanceLogs/containerGroupInstanceLogsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getContainerGroupInstanceLogsGeneralErrorContent } from '../notifications/clientToastNotificationContent/containerGroupInstanceLogs'
import {
  GetContainerGroupLogsAxiomTimeouErrorContent,
  getContainerGroupLogsConnectionTimeoutErrorContent,
  getContainerGroupLogsNotFoundErrorContent,
  getContainerGroupLogsOrganizationNotFoundErrorContent,
} from '../notifications/clientToastNotificationContent/containerGroupLogs'
import {
  getSystemEventLogsConnectionTimeoutErrorContent,
  getSystemEventLogsContainerGroupNotFoundErrorContent,
  getSystemEventLogsOrganizationNotFoundErrorContent,
} from '../notifications/clientToastNotificationContent/systemEventLogs'
import { getContainerGroupsPagePath, getOrganizationsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetContainerGroupInstanceLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupInstanceLogs.match),
    switchMap(({ payload: { containerGroupName, instanceId, instanceLogsQuery, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'pending' })),
        from(
          Promise.all([
            ContainerGroupsAPI.queryContainerGroupLogs({
              containerGroupName,
              organizationName,
              projectName,
              containerGroupLogsQuery: {
                ...instanceLogsQuery,
                instanceId: instanceId,
              },
            }),
            SystemLogsAPI.queryContainerGroupSystemLogs({
              containerGroupName,
              organizationName,
              projectName,
              systemLogsQuery: {
                ...instanceLogsQuery,
                instanceId: instanceId,
              },
            }),
          ]),
        ).pipe(
          mergeMap(([containerGroupLogsResponse, systemEventLogsResponse]) => {
            const containerGroupLogs: InstanceLog[] = containerGroupLogsResponse.items.map((log) => ({
              eventMessage: log.message,
              time: log.createTime,
            }))

            const systemEventLogs: InstanceLog[] = systemEventLogsResponse.items.map((log) => ({
              eventMessage: log.message,
              time: log.createTime,
            }))

            const instanceLogs: InstanceLog[] = [...containerGroupLogs, ...systemEventLogs]

            return concat(
              of(
                containerGroupInstanceLogsAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  instanceId,
                  containerGroupInstanceLogs: instanceLogs,
                }),
                setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) =>
            concat(
              error instanceof ResponseError
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as
                        | QueryContainerGroupLogsProblemType
                        | SystemLogsProblemType
                        | null
                      switch (errorType) {
                        case QueryContainerGroupLogsProblemType.ConnectionTimeout:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsConnectionTimeoutErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case QueryContainerGroupLogsProblemType.LogApiFailed:
                          return concat(
                            of(
                              showToastNotification(GetContainerGroupLogsAxiomTimeouErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case QueryContainerGroupLogsProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case QueryContainerGroupLogsProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        case SystemLogsProblemType.ConnectionTimeout:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsConnectionTimeoutErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case SystemLogsProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case SystemLogsProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              showToastNotification(getSystemEventLogsContainerGroupNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        default:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupInstanceLogsGeneralErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                      }
                    }),
                  )
                : concat(
                    of(
                      showToastNotification(getContainerGroupInstanceLogsGeneralErrorContent(intl)),
                      setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'failed' }),
                    ),
                    of(setRequestStatus({ request: 'getContainerGroupInstanceLogs', status: 'idle' })).pipe(delay(1)),
                  ),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollContainerGroupInstanceLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(startPollingContainerGroupInstanceLogs.match),
    switchMap(({ payload: { containerGroupName, instanceLogFilters, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'pending' })),
        timer(0, 10000).pipe(
          mergeMap(() =>
            from(
              Promise.all([
                ContainerGroupsAPI.queryContainerGroupLogs({
                  containerGroupName,
                  organizationName,
                  projectName,
                  containerGroupLogsQuery: instanceLogFilters,
                }),
                SystemLogsAPI.queryContainerGroupSystemLogs({
                  containerGroupName,
                  organizationName,
                  projectName,
                  systemLogsQuery: instanceLogFilters,
                }),
              ]),
            ).pipe(
              mergeMap(([containerGroupLogsResponse, systemEventLogsResponse]) => {
                const containerGroupLogs: InstanceLog[] = containerGroupLogsResponse.items.map((log) => ({
                  eventMessage: log.message,
                  time: log.createTime,
                }))

                const systemEventLogs: InstanceLog[] = systemEventLogsResponse.items.map((log) => ({
                  eventMessage: log.message,
                  time: log.createTime,
                }))

                const instanceLogs: InstanceLog[] = [...containerGroupLogs, ...systemEventLogs]

                return concat(
                  of(
                    containerGroupInstanceLogsAdded({
                      containerGroupName,
                      organizationName,
                      projectName,
                      instanceId: instanceLogFilters.instanceId,
                      containerGroupInstanceLogs: instanceLogs,
                    }),
                    setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'succeeded' }),
                  ),
                  of(setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' })).pipe(delay(1)),
                )
              }),
              catchError((error: unknown) =>
                concat(
                  error instanceof ResponseError
                    ? from(error.response.json()).pipe(
                        mergeMap((errorResponse) => {
                          const errorType = errorResponse.type as
                            | QueryContainerGroupLogsProblemType
                            | SystemLogsProblemType
                            | null
                          switch (errorType) {
                            case QueryContainerGroupLogsProblemType.ConnectionTimeout:
                              return concat(
                                of(
                                  showToastNotification(getContainerGroupLogsConnectionTimeoutErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                            case QueryContainerGroupLogsProblemType.LogApiFailed:
                              return concat(
                                of(
                                  showToastNotification(GetContainerGroupLogsAxiomTimeouErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                            case QueryContainerGroupLogsProblemType.OrganizationNotFound:
                              return concat(
                                of(
                                  showToastNotification(getContainerGroupLogsOrganizationNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                                of(
                                  navigateTo({
                                    path: getOrganizationsPagePath(),
                                  }),
                                ),
                              )
                            case QueryContainerGroupLogsProblemType.ContainerGroupNotFound:
                              return concat(
                                of(
                                  showToastNotification(getContainerGroupLogsNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                                of(
                                  navigateTo({
                                    path: getContainerGroupsPagePath(organizationName, projectName),
                                  }),
                                ),
                              )
                            case SystemLogsProblemType.ConnectionTimeout:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsConnectionTimeoutErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                            case SystemLogsProblemType.OrganizationNotFound:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsOrganizationNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                            case SystemLogsProblemType.ContainerGroupNotFound:
                              return concat(
                                of(
                                  showToastNotification(getSystemEventLogsContainerGroupNotFoundErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                            default:
                              return concat(
                                of(
                                  showToastNotification(getContainerGroupInstanceLogsGeneralErrorContent(intl)),
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                                ),
                                of(
                                  setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' }),
                                ).pipe(delay(1)),
                                of(stopPollingContainerGroupInstanceLogs()),
                              )
                          }
                        }),
                      )
                    : concat(
                        of(
                          showToastNotification(getContainerGroupInstanceLogsGeneralErrorContent(intl)),
                          setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'pollContainerGroupInstanceLogs', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(stopPollingContainerGroupInstanceLogs()),
                      ),
                ),
              ),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingContainerGroupInstanceLogs.match))),
        ),
      ),
    ),
  )
