import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeAddressElementChangeEvent, StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import type { FormEvent, FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Spinner } from '../../../../components/Spinner'
import { AddPaymentMethodMessages } from '../../messages'

interface StripeFormProps {
  /** The client secret for Stripe SetupIntent */
  clientSecret?: string
  /** Flag to indicate if the payment method is being added */
  isGetAddPaymentMethodSetupStatusPending?: boolean
  /** Flag to indicate if this is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for cancelling the form */
  onBack: () => void
  /** The optional back button label */
  onBackButtonLabel?: string
  /** The callback handler for when the Stripe submit form hits an error */
  onSubmitStripeFormError: (message?: string) => void
  /** The callback handler for when the Stripe submit form succeeded */
  onSubmitStripeFormSucceeded: () => void
}

export const StripeForm: FunctionComponent<StripeFormProps> = ({
  clientSecret,
  isGetAddPaymentMethodSetupStatusPending,
  isStorybookRender,
  onBack,
  onBackButtonLabel,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  const intl = useIntl()
  const stripe = useStripe()
  const elements = useElements()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentElementComplete, setIsPaymentElementComplete] = useState(false)
  const [isAddressElementComplete, setIsAddressElementComplete] = useState(false)
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true)
  const [isAddressElementLoading, setIsAddressElementLoading] = useState(true)

  const handleError = useCallback(
    (error: any) => {
      onSubmitStripeFormError(error.message)
    },
    [onSubmitStripeFormError],
  )

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault()

        if (stripe == null || elements == null) {
          return
        }

        setIsSubmitting(true)

        const url = new URL(window.location.href)
        const result = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: url.toString(),
          },
          redirect: 'if_required',
        })

        if (Object.prototype.hasOwnProperty.call(result, 'error') && result.error) {
          handleError(result.error)
          setIsSubmitting(false)
        } else if (result.setupIntent) {
          const { setupIntent } = result

          if (
            setupIntent.status === 'requires_action' ||
            setupIntent.status === 'requires_confirmation' ||
            setupIntent.status === 'requires_payment_method'
          ) {
            setIsSubmitting(false)
          }
          if (setupIntent.status === 'succeeded') {
            onSubmitStripeFormSucceeded()
            setIsSubmitting(false)
          }
        }
      } catch (error) {
        handleError(error)
        setIsSubmitting(false)
      }
    },
    [stripe, elements, onSubmitStripeFormSucceeded, handleError],
  )

  const handlePaymentElementChange = useCallback((event: StripePaymentElementChangeEvent) => {
    setIsPaymentElementComplete(event.complete)
  }, [])

  const handleAddressElementChange = useCallback((event: StripeAddressElementChangeEvent) => {
    setIsAddressElementComplete(event.complete)
  }, [])

  const isFormComplete = isPaymentElementComplete && isAddressElementComplete
  const isFormLoading = isPaymentElementLoading || isAddressElementLoading

  const navigationButtons = (
    <div className="mt-4 flex justify-between gap-6">
      <Button
        isFullWidth
        variant="green-outlined"
        onClick={onBack}
        isDisabled={isSubmitting || isGetAddPaymentMethodSetupStatusPending}
        type="button"
      >
        {onBackButtonLabel ?? intl.formatMessage(AddPaymentMethodMessages.backLabel)}
      </Button>
      <Button
        isDisabled={!isFormComplete}
        isFullWidth
        isLoading={isSubmitting || isGetAddPaymentMethodSetupStatusPending}
        type="submit"
        variant="green-filled"
      >
        {intl.formatMessage(AddPaymentMethodMessages.continueLabel)}
      </Button>
    </div>
  )

  if (isStorybookRender && !clientSecret) {
    return (
      <>
        <div className="flex h-60 w-full items-center justify-center bg-neutral-30">
          <span className="text-lg font-medium">{intl.formatMessage(AddPaymentMethodMessages.stripeText)}</span>
        </div>
        {navigationButtons}
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {isFormLoading && (
        <div className="my-10 flex w-full justify-center">
          <Spinner size="lg" />
        </div>
      )}
      <PaymentElement
        options={{ layout: 'tabs' }}
        onReady={() => setIsPaymentElementLoading(false)}
        onChange={handlePaymentElementChange}
        onLoadError={handleError}
      />
      <AddressElement
        className="my-4"
        options={{ mode: 'billing', fields: { phone: 'never' } }}
        onReady={() => setIsAddressElementLoading(false)}
        onChange={handleAddressElementChange}
        onLoadError={handleError}
      />
      {navigationButtons}
    </form>
  )
}
