import { FunctionComponent } from 'react'
import { CreateOrganizationWizardProgressBar } from '../../components/CreateOrganizationWizard/CreateOrganizationWizardProgressBar'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateOrganizationWizardInviteTeamMembersMainContent } from './components/CreateOrganizationWizardInviteTeamMembersMainContent'

export interface CreateOrganizationWizardInviteTeamMembersPageProps {
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
  /** A flag indicating that the request to invite a team member is pending. */
  isInviteTeamMemberPending: boolean
  /** The flag indicating that the request to invite a team member is successful. */
  isInviteTeamMemberSuccessful: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed concludes the create organization wizard */
  onGetStarted: () => void
  /** The callback handler for inviting a team member. */
  onInviteTeamMember: (email: string) => void
}

export const CreateOrganizationWizardInviteTeamMembersPage: FunctionComponent<
  CreateOrganizationWizardInviteTeamMembersPageProps
> = ({
  isFreeTrial,
  isInviteTeamMemberPending,
  isInviteTeamMemberSuccessful,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onGetStarted,
  onInviteTeamMember,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar
          currentPage={CreateOrganizationWizardPageSteps.InviteTeamMembers}
          isFreeTrial={isFreeTrial}
        />
      }
      MainColumn={
        <CreateOrganizationWizardInviteTeamMembersMainContent
          isInviteTeamMemberPending={isInviteTeamMemberPending}
          isInviteTeamMemberSuccessful={isInviteTeamMemberSuccessful}
          onGetStarted={onGetStarted}
          onInviteTeamMember={onInviteTeamMember}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
