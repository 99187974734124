import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { InviteTeamMembersMessages } from './messages'
import { InviteTeamMembersFormValues } from './models'

export const useInviteTeamMembersFormValidation = (): ObjectSchema<InviteTeamMembersFormValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .email(intl.formatMessage(InviteTeamMembersMessages.emailRequired))
          .required(intl.formatMessage(InviteTeamMembersMessages.emailRequired)),
      }),
    [intl],
  )
  return validationScheme
}
