import {
  accountPageRoutePath,
  apiKeyPageRoutePath,
  authenticationPageRoutePath,
  changePasswordPageRoutePath,
  containerGroupDetailsPageRoutePath,
  containerGroupInstanceDetailsPageRoutePath,
  containerGroupLogsPageRoutePath,
  containersPageRoutePath,
  createContainerGroupPageRoutePath,
  createOrganizationPageRoutePath,
  createProjectPageRoutePath,
  editContainerGroupPageRoutePath,
  editOrganizationPageRoutePath,
  editProjectPageRoutePath,
  forgotPasswordPageRoutePath,
  inferenceEndpointDetailsPageRoutePath,
  inferenceEndpointsMarketplacePageRoutePath,
  inviteTeamMemberPageRoutePath,
  noProjectsAvailablePageRoutePath,
  organizationBillingPageRoutePath,
  organizationsPageRoutePath,
  resetPasswordPageRoutePath,
  selectProductPageRoutePath,
  teamPageRoutePath,
  verifyAccountPageRoutePath,
} from '../../../routes/routePaths'

const genericArticleId = '66f6dc1b2de3eb1957dceeef'
const nonAuthenticatedArticleIds = ['671a69d5d6de7d3dda977460', '671a6829d0576266aafb6806']

interface HelpScoutRouteAndArticleIds {
  route: string
  articleIds: string[]
}

export const HelpScoutRoutesAndArticleIds: HelpScoutRouteAndArticleIds[] = [
  {
    route: authenticationPageRoutePath,
    articleIds: nonAuthenticatedArticleIds,
  },
  {
    route: verifyAccountPageRoutePath,
    articleIds: nonAuthenticatedArticleIds,
  },
  {
    route: resetPasswordPageRoutePath,
    articleIds: nonAuthenticatedArticleIds,
  },
  {
    route: forgotPasswordPageRoutePath,
    articleIds: nonAuthenticatedArticleIds,
  },
  {
    route: accountPageRoutePath,
    articleIds: [],
  },
  {
    route: changePasswordPageRoutePath,
    articleIds: [],
  },
  {
    route: apiKeyPageRoutePath,
    articleIds: [],
  },
  {
    route: organizationsPageRoutePath,
    articleIds: [],
  },
  {
    route: createOrganizationPageRoutePath,
    articleIds: ['66cd043a7b676f2079689aa3'],
  },
  {
    route: editOrganizationPageRoutePath,
    articleIds: [],
  },
  {
    route: teamPageRoutePath,
    articleIds: ['66cd052a082392452a0798c8'],
  },
  {
    route: inviteTeamMemberPageRoutePath,
    articleIds: [],
  },
  {
    route: organizationBillingPageRoutePath,
    articleIds: ['66cd05c5a62a7505fcf36ff1'],
  },
  {
    route: noProjectsAvailablePageRoutePath,
    articleIds: [],
  },
  {
    route: inferenceEndpointsMarketplacePageRoutePath,
    articleIds: ['66cd06857c359113ce1b0ae5'],
  },
  {
    route: inferenceEndpointDetailsPageRoutePath,
    articleIds: [],
  },
  {
    route: createProjectPageRoutePath,
    articleIds: ['66cd07827b676f2079689aaf'],
  },
  {
    route: selectProductPageRoutePath,
    articleIds: [],
  },
  {
    route: editProjectPageRoutePath,
    articleIds: [],
  },
  {
    route: containersPageRoutePath,
    articleIds: [],
  },
  {
    route: containerGroupDetailsPageRoutePath,
    articleIds: ['66cd088d0d7d861662423e20'],
  },
  {
    route: createContainerGroupPageRoutePath,
    articleIds: ['66cd082d16e6a80d1e1d58c2'],
  },
  {
    route: editContainerGroupPageRoutePath,
    articleIds: [],
  },
  {
    route: containerGroupLogsPageRoutePath,
    articleIds: [],
  },
  {
    route: containerGroupInstanceDetailsPageRoutePath,
    articleIds: ['67059584cc20d5120aca3722'],
  },
].map((helpScoutRoute) => ({
  ...helpScoutRoute,
  articleIds: [...helpScoutRoute.articleIds, genericArticleId],
}))
