import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  InstanceLog,
  InstanceLogsQuery,
  QueryInstanceLogsRequest,
} from '../../components/instances/InstanceDetailsMainContent/components/InstanceLogs/models'
import { configureContainerGroupInstanceLogsEntityId } from './utils'

export interface containerGroupInstanceLogsProps {
  organizationName: string
  projectName: string
  instanceId: string
  containerGroupName: string
  containerGroupInstanceLogs: InstanceLog[]
}

export const containerGroupInstanceLogsAdapter = createEntityAdapter<containerGroupInstanceLogsProps, string>({
  selectId: (item) =>
    configureContainerGroupInstanceLogsEntityId(
      item.containerGroupName,
      item.organizationName,
      item.projectName,
      item.instanceId,
    ),
  sortComparer: (a, b) => a.containerGroupName.localeCompare(b.containerGroupName),
})

export const containerGroupInstanceLogsSlice = createSlice({
  name: 'containerGroupInstanceLogs',
  initialState: containerGroupInstanceLogsAdapter.getInitialState(),
  reducers: {
    getContainerGroupInstanceLogs(_state, _action: PayloadAction<QueryInstanceLogsRequest>) {},
    containerGroupInstanceLogsAdded: containerGroupInstanceLogsAdapter.upsertOne,
    startPollingContainerGroupInstanceLogs(
      _state,
      _action: PayloadAction<{
        containerGroupName: string
        organizationName: string
        projectName: string
        instanceLogFilters: InstanceLogsQuery
      }>,
    ) {},
    stopPollingContainerGroupInstanceLogs() {},
  },
})

export const {
  containerGroupInstanceLogsAdded,
  getContainerGroupInstanceLogs,
  startPollingContainerGroupInstanceLogs,
  stopPollingContainerGroupInstanceLogs,
} = containerGroupInstanceLogsSlice.actions
