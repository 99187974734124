import { defineMessages } from 'react-intl'

export const GetContainerGroupInstanceLogsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve your container group instance logs. Try again and if this issue persists {contact_support}.',
    id: 'Cafr69sL',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get container instance logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'vUDD6jA6',
    description:
      'The text shown for the contact support link in the get container instance logs error toast notification.',
  },
  title: {
    defaultMessage: 'Error getting container group instance logs',
    id: '6bglbvxL',
    description:
      'The toast notification title shown when we encounter an error on their request to get container instance logs.',
  },
})
