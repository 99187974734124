import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { OrganizationInformationFormValues } from './models'

export const useOrganizationInformationFormValidation = (): ObjectSchema<OrganizationInformationFormValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        organizationName: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
      }),
    [],
  )
  return validationScheme
}
