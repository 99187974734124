import { defineMessages } from 'react-intl'

export const InstanceLogsMessages = defineMessages({
  downloadButtonLabel: {
    defaultMessage: 'Download',
    id: 'Q+801YGf',
    description: 'The label for the Download button.',
  },
  eventMessageTableHeader: {
    defaultMessage: 'Event/Message',
    id: '5rwIb8KM',
    description: 'The label for the Event/Message.',
  },
  filterPlaceholder: {
    defaultMessage: 'Filter',
    id: '89h1DB61',
    description: 'The placeholder for the filter input.',
  },
  instanceLogsDescriptionText: {
    defaultMessage: `View the combined system and container logs filtered for this specific instance. By default, this page auto-refreshes every 10 seconds. To turn this off, click “Pause Auto-Refresh.”`,
    id: '87QmtmtZ',
    description: 'Instance logs description',
  },
  instanceLogsTitle: {
    defaultMessage: 'Combined Instance Logs',
    id: 'wOGV6L1W',
    description: 'The title for the Instance Logs tab.',
  },
  queryButtonLabel: {
    defaultMessage: 'Query',
    id: '9bubCHDN',
    description: 'The label for the Query button.',
  },
  timeTableHeader: {
    defaultMessage: 'Time (UTC)',
    id: 'durIzk6P',
    description: 'The label for the Time.',
  },
})
