// Auth
export const authenticationPageRoutePath = '/'
export const verifyAccountPageRoutePath = '/verify-account'
export const resetPasswordPageRoutePath = '/reset-password'
export const forgotPasswordPageRoutePath = '/forgot-password'

// Account
export const accountPageRoutePath = '/account'
export const changePasswordPageRoutePath = '/account/change-password'
export const apiKeyPageRoutePath = '/api-key'
export const changeAPIKeyPageRoutePath = '/api-key/change'

// Create Organization Wizard
export enum CreateOrganizationWizardPath {
  AddPaymentMethod = '/create-organization-wizard/:createOrganizationWizardId/add-payment-method',
  ConfigureCreditSettings = '/create-organization-wizard/:createOrganizationWizardId/configure-credit-settings',
  InviteTeamMembers = '/create-organization-wizard/:createOrganizationWizardId/invite-team-members',
  OrganizationInformation = '/create-organization-wizard/:createOrganizationWizardId/organization-information',
  PaymentMethodSummary = '/create-organization-wizard/:createOrganizationWizardId/payment-method-summary',
}

// Organizations
export const organizationsPageRoutePath = '/organizations'
export const createOrganizationPageRoutePath = '/organizations/new'
export const editOrganizationPageRoutePath = '/organizations/:organizationName/edit'
export const webhookSecretKeyRoutePath = '/organizations/:organizationName/webhook-secret-key'
export const teamPageRoutePath = '/organizations/:organizationName/team'
export const inviteTeamMemberPageRoutePath = '/organizations/:organizationName/team/invite'
export const noProjectsAvailablePageRoutePath = '/organizations/:organizationName/no-projects'

//Billing Page
export const organizationBillingPageRoutePath = '/organizations/:organizationName/billing'
export const billingAutoRechargePageRoutePath = '/organizations/:organizationName/billing/auto-recharge'

// Billing Manually Add Credit Wizard
export enum ManuallyAddCreditWizardPath {
  AddCredits = '/organizations/:organizationName/billing/manual-credit-request/add-credit',
  ChangePaymentMethod = '/organizations/:organizationName/billing/manual-credit-request/change-payment-method',
  PaymentSummary = '/organizations/:organizationName/billing/manual-credit-request/payment-summary',
}

// Inference Endpoints
export const inferenceEndpointsMarketplacePageRoutePath = '/organizations/:organizationName/inference-endpoints/'
export const inferenceEndpointDetailsPageRoutePath =
  '/organizations/:organizationName/inference-endpoints/:inferenceEndpointName'

// Projects
export const createProjectPageRoutePath = '/organizations/:organizationName/projects/new'
export const selectProductPageRoutePath = '/organizations/:organizationName/projects/:projectName'
export const editProjectPageRoutePath = '/organizations/:organizationName/projects/:projectName/edit'

// Containers
export const containersPageRoutePath = '/organizations/:organizationName/projects/:projectName/containers'
export const containerGroupDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/'
export const createContainerGroupPageRoutePath = '/organizations/:organizationName/projects/:projectName/containers/new'
export const editContainerGroupPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/edit'
export const containerGroupLogsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/logs'
export const containerGroupInstanceDetailsPageRoutePath =
  '/organizations/:organizationName/projects/:projectName/containers/:containerGroupName/instances/:instanceId'
