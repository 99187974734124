import classNames from 'classnames'
import { FunctionComponent } from 'react'

export const DocumentIcon: FunctionComponent = () => {
  return (
    <div className={classNames('size-10 rounded-full bg-green-10 text-xs font-medium')}>
      <div className="flex size-full items-center justify-center text-green-100">
        <i className={classNames('fa-regular fa-file-lines text-xl')} />
      </div>
    </div>
  )
}
