import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { CreateOrganizationWizardState } from '@saladtechnologies/openapi-cloud-portal-browser'
import { CreateOrganizationWizardPageSteps } from '../../components/CreateOrganizationWizard/models'

export const createOrganizationWizardAdapter = createEntityAdapter<CreateOrganizationWizardState>({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
})

export const createOrganizationWizardSlice = createSlice({
  name: 'createOrganizationWizardSlice',
  initialState: createOrganizationWizardAdapter.getInitialState(),
  reducers: {
    checkOrganizationNameAvailability(
      _state,
      _action: PayloadAction<{ organizationName: string; turnstileSuccessToken: string }>,
    ) {},
    confirmPaymentSummary(
      _state,
      _action: PayloadAction<{ organizationReservedName: string; createOrganizationWizardId: string }>,
    ) {},
    createCreateOrganizationWizard() {},
    createOrganizationWizardAdded: createOrganizationWizardAdapter.upsertOne,
    createOrganizationWizardRemoved: createOrganizationWizardAdapter.removeOne,
    createOrganizationWizardsReceived(
      state,
      action: PayloadAction<{ createOrganizationWizards: CreateOrganizationWizardState[] }>,
    ) {
      createOrganizationWizardAdapter.setAll(state, action.payload.createOrganizationWizards)
    },
    deleteCreateOrganizationWizard(
      _state,
      _action: PayloadAction<{ createOrganizationWizardId: string; createOrganizationWizardReservedName: string }>,
    ) {},
    editCreateOrganizationWizard(
      _state,
      _action: PayloadAction<{
        createOrganizationWizard: CreateOrganizationWizardState
        currentRoute: CreateOrganizationWizardPageSteps
      }>,
    ) {},
    getAddPaymentMethodPageData(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    getAddPaymentMethodSetupStatus(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    getCreateOrganizationWizard(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    listCreateOrganizationWizards() {},
    stopPollingForCreateOrganizationWizardSubmission(
      _state,
      _action: PayloadAction<{ createOrganizationWizardId: string }>,
    ) {},
  },
})

export const {
  checkOrganizationNameAvailability,
  confirmPaymentSummary,
  createCreateOrganizationWizard,
  createOrganizationWizardAdded,
  createOrganizationWizardRemoved,
  createOrganizationWizardsReceived,
  deleteCreateOrganizationWizard,
  editCreateOrganizationWizard,
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
  getCreateOrganizationWizard,
  listCreateOrganizationWizards,
  stopPollingForCreateOrganizationWizardSubmission,
} = createOrganizationWizardSlice.actions
